import mapImage from "../../../assets/images/map-image.jpg";

export default function () {
  return (
    <>
      <div style={{ height: `calc(100vh - 128px)`, width: '100%', opacity: '0.3', background: '#000', position: 'absolute' }}>
      </div>
      <div style={{ height: `calc(100vh - 128px)` }}>
        <div style={{
          background: 'cover',
          backgroundImage: `url(${mapImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100%'
        }}>
        </div>
      </div>
    </>
  );
}
