import { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { SceneMode } from 'cesium';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/commonHooks';
import { d2Icon, d3Icon, shareIcon } from '../../../shared/components/Icons';
import { changeMapMode } from '../../view/reducers/viewSlice';
import { useCurrentPath } from '../hooks/useCurrentPath';
import ShareDialog from './ShareDialog';

const viewRoute = [{ path: '/projects/view/:id?' }];

const ViewAppBar = () => {
  const currentRoute = useCurrentPath(viewRoute);
  const dispatch = useAppDispatch();
  const { mapMode, project } = useAppSelector(state => state.view);
  const [isShareDialog, setIsShareDialog] = useState(false);

  function handleClick(mode: SceneMode) {
    dispatch(changeMapMode(mode));
  }

  function toggleShareDialog() {
    setIsShareDialog(!isShareDialog);
  }

  return (
    <>
      {currentRoute && project &&
        <>
          <AppBarSection className={"actions-appbar-section"}>
            <Button
              fillMode={'flat'}
              svgIcon={d2Icon}
              disabled={mapMode === SceneMode.SCENE2D}
              onClick={() => handleClick(SceneMode.SCENE2D)}>
              2D view
            </Button>
            <Button
              className="ms-1"
              fillMode={'flat'}
              svgIcon={d3Icon}
              disabled={mapMode === SceneMode.SCENE3D}
              onClick={() => handleClick(SceneMode.SCENE3D)}>
              3D view
            </Button>
          </AppBarSection>

          <AppBarSpacer />

          <AppBarSection className={"actions-appbar-section"}>
            <Button
              fillMode={'flat'}
              svgIcon={shareIcon}
              onClick={() => toggleShareDialog()}>
              Share Link
            </Button>
          </AppBarSection>
        </>
      }

      {isShareDialog && <ShareDialog projectId={project?.id} onClose={toggleShareDialog} />}
    </>
  )
}

export default ViewAppBar;
