import { EntityType } from "./LegendModels";
import { Barrier, BuildingRow, ContourZone, EntityBase, GroundZone, Receiver, Roadway, TerrainLine, TreeZone } from "./ProjectModels";

export function isBarrier(entity: EntityBase): entity is Barrier {
  return (entity as Barrier).type === EntityType.barriers;
}

export function isBuildingRow(entity: EntityBase): entity is BuildingRow {
  return (entity as BuildingRow).type === EntityType.buildingRows;
}

export function isContourZone(entity: EntityBase): entity is ContourZone {
  return (entity as ContourZone).type === EntityType.contourZones;
}

export function isGroundZone(entity: EntityBase): entity is GroundZone {
  return (entity as GroundZone).type === EntityType.groundZones;
}

export function isTreeZone(entity: EntityBase): entity is TreeZone {
  return (entity as TreeZone).type === EntityType.treeZones;
}

export function isReceiver(entity: EntityBase): entity is Receiver {
  return (entity as Receiver).type === EntityType.receivers;
}

export function isRoadway(entity: EntityBase): entity is Roadway {
  return (entity as Roadway).type === EntityType.roadways;
}

export function isTerrainLine(entity: EntityBase): entity is TerrainLine {
  return (entity as TerrainLine).type === EntityType.terrainLines;
}
