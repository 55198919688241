export class ProjectsListItem {
  id!: string;
  description!: string;
  createdDate!: string;
  runTitle!: string;
  isOwned!: boolean;
}

export enum ProjectCollaborationType {
  any = 0,
  owned = 1,
  shared = 2
}
