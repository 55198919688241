import { axios } from "../../shared/api/axios";
import { ForgotPasswordModel, LoginModel, LoginResultModel, RegisterModel, ResetPasswordModel } from "../models/AccountModel";

class AccountService {
  login(model: LoginModel): Promise<LoginResultModel> {
    return axios.post('/account/login', model).then(x => x.data);
  }

  register(model: RegisterModel): Promise<void> {
    return axios.post('/account/register', model);
  }

  forgotPassword(model: ForgotPasswordModel): Promise<void> {
    return axios.post('/account/forgot-password', model);
  }

  resetPassword(model: ResetPasswordModel): Promise<void> {
    return axios.post('/account/reset-password', model);
  }
}

export default new AccountService();
