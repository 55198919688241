import { jwtDecode } from "jwt-decode";
import { randomString } from "../../shared/services/random";
import { Account, LoginResultModel } from "../models/AccountModel";

class AccountContextService {
  get account(): Account | undefined {
    return this.accessToken ? this.createAccount(this.accessToken) : undefined;
  }

  get accessToken(): string | undefined {
    const accessToken = localStorage.getItem('accessToken');
    return accessToken || undefined;
  }

  get guestCode(): string | undefined {
    let guestCode = localStorage.getItem('guestCode');
    if (!guestCode) {
      guestCode = randomString(8);
      localStorage.setItem('guestCode', guestCode);
    }

    return guestCode;
  }

  get isNew(): boolean {
    const accessToken = localStorage.getItem('accessToken');
    const guestCode = localStorage.getItem('guestCode');

    return !(accessToken || guestCode);
  }

  finishLogin(loginResult: LoginResultModel) {
    localStorage.setItem('accessToken', loginResult.accessToken);
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('guestCode');
  }

  private createAccount(accessToken: string): Account {
    const jwt = this.getDecodedAccessToken(accessToken);
    if (!jwt) {
      return {};
    }

    return {
      firstName: jwt.given_name,
      lastName: jwt.family_name
    };
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
}

export default new AccountContextService();
