import { FC } from "react";

import {
  Card,
  CardTitle,
  CardBody
} from "@progress/kendo-react-layout";
import { DropDownButton, DropDownButtonItemClickEvent } from "@progress/kendo-react-buttons";

import moment from "moment";

import xmlFileIcon from './../../assets/xml-file-icon.svg';
import actionsIcon from './../../assets/actions-icon.svg';
import { ProjectsListItem } from "../../models/ProjectsModel";
import Description from "./Description";

enum ActionTypes {
  view = "View",
  delete = "Delete"
}

interface ProjectCardProps {
  project: ProjectsListItem;
  onDelete: (id: string) => void;
  onView: (id: string) => void;
}

const ProjectCard: FC<ProjectCardProps> = ({ project, onDelete, onView }) => {
  const createdDate = moment(project.createdDate).format('MM.DD.YYYY');
  
  const actionButtons: ActionTypes[]  = [];
  actionButtons.push(ActionTypes.view);
  if (project.isOwned) {
    actionButtons.push(ActionTypes.delete);
  }

  function handleItemClick(e: DropDownButtonItemClickEvent) {
    if (e.item === ActionTypes.view) {
      onView(project.id);
      return;
    }

    if (e.item === ActionTypes.delete) {
      onDelete(project.id);
      return;
    }
  }

  function actionMenuItemRenderer(props: any) {
    return (
      <div className={props.item === ActionTypes.delete ? "text-danger" : ""}>
        {props.item}
      </div>
    );
  }

  return (
    <Card className="d-flex flex-row" style={{ maxWidth: 327, minWidth: 327 }}>
      <div className='ms-3 mt-3'>
        <img src={xmlFileIcon} />
      </div>

      <CardBody style={{ maxWidth: 206, minWidth: 206 }}>
        <CardTitle 
          className="text-20 text-decoration-underline mb-0" 
          style={{cursor: 'pointer'}}
        >
            <p onClick={() => onView(project.id)}>{project.runTitle}</p>
        </CardTitle>
        <p className="text-13 text-secondary mb-2" style={{ color: '#616E7C' }}>Created on {createdDate}</p>
        {project.description && <Description text={project.description} />}
      </CardBody>

      <div className='me-3 mt-3'>
        <DropDownButton items={actionButtons} fillMode={'flat'} onItemClick={handleItemClick}
          imageUrl={actionsIcon} itemRender={actionMenuItemRenderer} />
      </div>
    </Card>
  )
}

export default ProjectCard