import { axios } from "../../../shared/api/axios";

export interface ShareProjectResult {
  code: string;
  url: string;
}

export function ShareProject(id: string): Promise<ShareProjectResult> {
  return axios.post(`/projects/${id}/share`).then(x => x.data);
}
