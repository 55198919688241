import Axios, { InternalAxiosRequestConfig } from 'axios';
import accountContext from '../../account/services/accountContextService';

function addAuthentication(config: InternalAxiosRequestConfig) {
  const token = accountContext.accessToken;
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
    return;
  }

  const guestCode = accountContext.guestCode;
  if (guestCode) {
    config.headers.authorization = `Guest ${guestCode}`;
    return;
  }
}

function authRequestInterceptor(config: InternalAxiosRequestConfig) {
  addAuthentication(config);
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: (window as any).app.config.apiBaseUrl
});

axios.interceptors.request.use(authRequestInterceptor);