import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { AppNotification, FormNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { PasswordField } from "../../shared/components/Inputs";
import { passwordValidator, passwordsMatchValidator, requiredValidator } from "../../shared/validators";
import accountService from "../services/accountService";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [tempModel, setTempModel] = useState({});
  const [formNotificationMessage, setFormNotificationMessage] = useState<NotificationMessage>();
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const passwordValidators = [requiredValidator, passwordValidator];
  const confirmPasswordValidators = [
    requiredValidator,
    passwordValidator,
    (value: any, getter: (field: string) => any) => passwordsMatchValidator(value, getter("newPassword"))
  ];

  async function handleSubmit(model: any) {
    const { userName } = model;
    setTempModel({ userName });

    setIsLoading(true);

    try {
      model.userName = searchParams.get("userName");
      model.token = searchParams.get("token");
      await accountService.resetPassword(model);
      setNotificationMessage(NotificationMessage.success("Your password has been successfully changed!"));

      navigate("../login");
    } catch (error: any) {
      setFormNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  function handleInputChange() {
    setFormNotificationMessage(undefined);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="primary-card col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle className="heading-1 text-primary">Set Password</CardTitle>
        </CardHeader>
        <CardBody>
          <FormNotification className="mb-45" notificationMessage={formNotificationMessage} />

          <Form
            initialValues={tempModel}
            onSubmit={handleSubmit}
            render={(props) => (
              <FormElement id={"form"}>
                <PasswordField name={"newPassword"} label={"New Password"}
                  validator={passwordValidators} onChange={handleInputChange} />

                <PasswordField name={"confirmPassword"} label={"Confirm New Password"}
                  validator={confirmPasswordValidators} onChange={handleInputChange} />
              </FormElement>
            )}
          />

          <div className="mt-45 d-flex justify-content-between align-items-center">
            <Button themeColor={"primary"} type={"submit"} form={"form"}>Set new password</Button>
          </div>
        </CardBody>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
