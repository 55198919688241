import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { AppNotification, FormNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { FormInput, PasswordField } from "../../shared/components/Inputs";
import { emailValidator, requiredValidator } from "../../shared/validators";
import accountService from "../services/accountService";
import accountContextService from "../services/accountContextService";

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [tempModel, setTempModel] = useState({});
  const [formNotificationMessage, setFormNotificationMessage] = useState<NotificationMessage>();
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();
  const navigate = useNavigate();

  const emailValidators = [requiredValidator, emailValidator];

  async function handleLogin(model: any) {
    const { userName } = model;
    setTempModel({ userName });

    setIsLoading(true);

    try {
      const loginResult = await accountService.login(model);
      accountContextService.finishLogin(loginResult);
      navigate("/");
    } catch (error: any) {
      setFormNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  function handleInputChange() {
    setFormNotificationMessage(undefined);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="primary-card col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle className="heading-1 text-primary">Login</CardTitle>
        </CardHeader>
        <CardBody>
          <FormNotification className="mb-45" notificationMessage={formNotificationMessage} />

          <Form
            initialValues={tempModel}
            onSubmit={handleLogin}
            render={(formRenderProps) => (
              <FormElement id={"form"}>
                <Field name={"userName"} type={"Email"} component={FormInput} label={"Email"}
                  validator={emailValidators} onChange={handleInputChange} />

                <PasswordField name={"password"} label={"Password"}
                  validator={requiredValidator} onChange={handleInputChange} />
              </FormElement>
            )}
          />


          <div className="mt-45 d-flex justify-content-between align-items-center">
            <Button themeColor={"primary"} type={"submit"} form={"form"}>Log in with email</Button>
            <Link to="../forgot-password"><strong>I forgot my password</strong></Link>
          </div>

          <div className="mt-45">
            <span className="text-primary">
              Do not have an account? <Link to="../register"><strong>Sign up here</strong></Link>
            </span>
          </div>
        </CardBody>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
