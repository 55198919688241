import { FC, useState } from 'react'

const MAX_SYMBOLS = 62;

interface DecriptionProps {
  text: string;
}

const Description: FC<DecriptionProps> = ({ text }) => {

  const [showMore, setShowMore] = useState(false);

  function toggleShowMore() {
    setShowMore(!showMore);
  }

  if (text.length < MAX_SYMBOLS) {
    return (
      <p className="text-13" style={{ overflowWrap: 'break-word' }}>{text}</p>
    )
  }

  return (
    <>
      {!showMore ?
        <p className="text-13 mb-2" style={{ overflowWrap: 'break-word' }}>{text.slice(0, MAX_SYMBOLS) + '...'}</p> :
        <p className="text-13 mb-2" style={{ overflowWrap: 'break-word' }}>{text}</p>
      }
      <p
        className="text-11"
        style={{ textDecoration: 'underline', cursor: 'pointer', textAlign: 'right' }}
        onClick={toggleShowMore}>
        Show {!showMore ? 'more' : 'less'}
      </p>
    </>
  )
}

export default Description;