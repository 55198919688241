import { Cartesian2, Color, PolylineArrowMaterialProperty } from "cesium";
import { Project, Roadway } from "../../models/ProjectModels";
import { MapperBase, MappingConfig } from "../mapperBase";

class RoadwayMapper extends MapperBase {
  map(project: Project, roadways: Roadway[], config: MappingConfig) {
    const alpha = config.opacity / 100;
    const mapped = roadways.map(x => this.mapPolyline(
      {
        ...x,
        ...this.getLabelAndPointMaterials(alpha),
        showEntityLabels: config.showEntityLabels,
        showPointLabels: config.showPointLabels,
        clampToGround: true,
        width: 10,
        labelPixelOffset: new Cartesian2(25, -30),
        polylineMaterial:  new PolylineArrowMaterialProperty(Color.fromAlpha(Color.BLACK, alpha))
      }, 
      project)
    );

    return (
      <>
        {mapped}
      </>
    );
  }
}

export default new RoadwayMapper();
