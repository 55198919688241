import { Cartesian2, Cartesian3, Cartographic, Color, PolylineDashMaterialProperty } from "cesium";
import { BuildingRow, Project } from "../../models/ProjectModels";
import { MapperBase, MappingConfig } from "../mapperBase";
import { Entity, WallGraphics } from "resium";

class BuildingRowMapper extends MapperBase {
  map(project: Project, buildingRows: BuildingRow[], config: MappingConfig) {
    return (
      <>
        {this.map2D(project, buildingRows, config)}
        {this.map3D(project, buildingRows, config)}
      </>
    );
  }

  private map2D(project: Project, buildingRows: BuildingRow[], config: MappingConfig): JSX.Element {
    const alpha = config.opacity / 100;
    const buildingRowsElements = buildingRows.map(x => this.mapPolyline(
      {
        ...x,
        ...this.getLabelAndPointMaterials(alpha),
        showEntityLabels: config.showEntityLabels,
        showPointLabels: config.showPointLabels,
        clampToGround: true,
        labelPixelOffset: new Cartesian2(25, -50),
        polylineMaterial: new PolylineDashMaterialProperty({ color: Color.fromAlpha(Color.RED, alpha) })
      },
      project)
    );

    return (
      <>
        {buildingRowsElements}
      </>
    );
  }

  private map3D(project: Project, buildingRows: BuildingRow[], config: MappingConfig) {
    const buildingRows3D = buildingRows.map(x => this.mapBuildingRow3D(project, x, config));

    return (
      <>
        {buildingRows3D}
      </>
    );
  }

  private mapBuildingRow3D(project: Project, buildingRow: BuildingRow, config: MappingConfig) {
    let maxHeight = buildingRow.averageHeight;
    const points = this.projectPointsLatLong(project, buildingRow.points);
    const minHeights: number[] = [];
    const pointsFlatArray: number[] = [];
    points.forEach(x => {
      let minHeight = 0;
      if (config.globe) {
        const height = config.globe.getHeight(Cartographic.fromDegrees(x[0], x[1]));
        if (height && height > 0) {
          minHeight += height;
        }
      }

      pointsFlatArray.push(x[0], x[1], minHeight + maxHeight);
      minHeights.push(minHeight);
    });

    const positionsArray = Cartesian3.fromDegreesArrayHeights(pointsFlatArray);
    const material = Color.fromAlpha(Color.DARKGRAY, config.opacity / 100);

    return (
      <>
        <Entity key={buildingRow.id}>
          <WallGraphics
            positions={positionsArray}
            material={material}
            minimumHeights={minHeights}
          />
        </Entity>
      </>
    );
  }
}

export default new BuildingRowMapper();