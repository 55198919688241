import { Link } from "react-router-dom";

export function LinkButton(props: any) {
  const { to, size = "md", fillMode = "solid", themeColor = "base", className = "" } = props;

  return (
    <Link
      to={to}
      className={
        `k-button k-button-${size} k-rounded-${size} k-button-${fillMode} k-button-${fillMode}-${themeColor} 
        ${className}`}>
      {props.children}
    </Link>
  );
}