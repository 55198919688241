import { FC, useEffect, useRef, useState } from 'react';
import { Slider } from '@progress/kendo-react-inputs';
import { SvgIcon } from '@progress/kendo-react-common';
import { Tooltip } from "@progress/kendo-react-tooltip";
import { eyeIcon, eyeSlashIcon, textIcon, textSlashIcon } from '../../../shared/components/Icons';
import { EntityType, EntityView } from '../models/LegendModels';
import LegendIcon from './LegendIcon';

function getEntityName(entity: EntityType): string {
  switch (entity) {
    case EntityType.baseMap:
      return "Base Map";

    case EntityType.barriers:
      return "Barriers";

    case EntityType.buildingRows:
      return "Building Rows";

    case EntityType.contourZones:
      return "Contour Zones";

    case EntityType.groundZones:
      return "Ground Zones";

    case EntityType.treeZones:
      return "Tree Zones";

    case EntityType.receivers:
      return "Receivers";

    case EntityType.roadways:
      return "Roadways";

    case EntityType.terrainLines:
      return "Terrain Lines";

    default:
      throw new Error("Not supported entity type");
  }
}

const ShowEntitiesButton = (props: { settings: EntityView, handleShowClick: (setting: EntityView) => void }) => {
  const { disableSetting, show: isShown } = props.settings;
  const icon = disableSetting || !isShown ? eyeSlashIcon : eyeIcon;

  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ cursor: disableSetting ? 'default' : 'pointer' }}
      onClick={() => !disableSetting && props.handleShowClick(props.settings)}
    >
      <SvgIcon icon={icon} width={24} height={24} className={disableSetting ? "text-disabled" : ""} />
    </div>
  );
};

const ShowLabelsButtons = (props: {
  settings: EntityView,
  handleShowEntityLabelsClick: (settings: EntityView) => void,
  handleShowPointLabelsClick: (settings: EntityView) => void
}) => {
  const { showEntityLabels, showPointLabels, disableSetting, show: isShown, hasLabels } = props.settings;
  const iconText = showPointLabels ? textIcon : textSlashIcon;
  const disabled = disableSetting || !isShown;

  function handleEntityLabelsClick() {
    props.handleShowEntityLabelsClick(props.settings);
  }

  function handlePointLabelsClick() {
    props.handleShowPointLabelsClick(props.settings);
  }

  return (
    <>
      {hasLabels &&
        <>
          <ShowLabelsButton
            showLabels={showEntityLabels}
            disabled={disabled}
            tooltip='Object labels'
            handleShowClick={handleEntityLabelsClick} />

          <ShowLabelsButton
            showLabels={showPointLabels}
            disabled={disabled}
            tooltip='Point labels'
            handleShowClick={handlePointLabelsClick} />
        </>
      }
    </>
  );
}

const ShowLabelsButton = (props: {
  showLabels: boolean,
  disabled: boolean,
  tooltip: string,
  handleShowClick: () => void
}) => {
  const className = props.disabled ? 'text-disabled' :
    props.showLabels ? 'text-success' : 'text-danger';

  function handleClick() {
    if (props.disabled) {
      return;
    }

    props.handleShowClick();
  }

  return (
    <>
      <div
        className='d-flex justify-content-center align-items-center'
        style={{ cursor: props.disabled ? 'default' : 'pointer' }}
        onClick={handleClick}
      >
        <Tooltip anchorElement='target' openDelay={500} position='bottom'>
          <span
            title={props.tooltip}
            className={className}
            style={{ fontSize: '32px', lineHeight: '24px' }}>
            {'\u25CF'}
          </span>
        </Tooltip>
      </div>
    </>
  );
}

interface LegendItemProps {
  setting?: EntityView;
  handleShowClick: (setting: EntityView) => void;
  handleOpacityChange: (setting: EntityView, value: number) => void;
  handleShowEntityLabelsClick: (setting: EntityView) => void;
  handleShowPointLabelsClick: (setting: EntityView) => void;
}

const LegendItem: FC<LegendItemProps> = ({
  setting,
  handleShowClick,
  handleOpacityChange,
  handleShowEntityLabelsClick,
  handleShowPointLabelsClick
}) => {
  const [sliderValue, setSliderValue] = useState<number>(100);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      setting && handleOpacityChange(setting, sliderValue);
    }, 250);
  }, [sliderValue]);

  if (!setting) {
    return null;
  }

  const isDisabled = setting.disableSetting || !setting.show;

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          <LegendIcon entityType={setting.type} disabled={isDisabled} />
          <span style={{ marginLeft: '0.5rem' }} className={isDisabled ? "text-disabled" : ""}>
            {getEntityName(setting.type)}
          </span>
        </div>

        <div className='d-flex align-items-center'>
          <ShowLabelsButtons
            settings={setting}
            handleShowEntityLabelsClick={handleShowEntityLabelsClick}
            handleShowPointLabelsClick={handleShowPointLabelsClick} />

          <span className='ms-1'>
            <ShowEntitiesButton settings={setting} handleShowClick={handleShowClick} />
          </span>
        </div>
      </div>

      <Slider
        className='w-100'
        disabled={isDisabled}
        step={1}
        defaultValue={100}
        min={0}
        max={100}
        onChange={(e) => setSliderValue(e.value)}
      />
    </div>
  )
}

export default LegendItem;