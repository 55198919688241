import { EntityBase } from "./ProjectModels";

export enum EntityType {
  baseMap = 0,
  treeZones = 1,
  groundZones = 2,
  contourZones = 3,
  roadways = 4,
  barriers = 5,
  buildingRows = 6,
  terrainLines = 7,
  receivers = 8
}

export enum EntityViewStatus {
  initial = 0,
  loading = 1,
  loaded = 2
}

export class EntityView {
  type!: EntityType;
  status!: EntityViewStatus;
  entities!: EntityBase[];
  disableSetting!: boolean;
  opacity!: number;
  show!: boolean;
  showEntityLabels!: boolean;
  showPointLabels!: boolean;
  hasLabels!: boolean;
}
