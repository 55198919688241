import proj4 from "proj4";
import { CoordinateSystemInfo, CoordinateSystemType } from "../models/ProjectionsModels";
import { projectionsDefinitions } from "../models/projectionsDefinitions";

class ProjectionService {
  constructor() {
    proj4.defs(projectionsDefinitions);
  }

  projectToLatLong(coordinateSystemInfo: CoordinateSystemInfo, x: number, y: number): [number, number] {
    const { coordinateSystemType, coordinateSystem } = coordinateSystemInfo;
    
    if (coordinateSystemType === CoordinateSystemType.projected) {
      const [long, lat] = proj4(coordinateSystem, "EPSG:4326", [x, y]);
      return [long, lat];
    }

    if (coordinateSystemType === CoordinateSystemType.cartesian) {
      const [long, lat] = proj4("EPSG:3857", "EPSG:4326", [x, y]);
      return [long, lat];
    }

    return [x, y];
  }
}

export default new ProjectionService();