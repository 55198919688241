import { CardBody } from "@progress/kendo-react-layout";
import { Card } from "@progress/kendo-react-layout";
import { LinkButton } from "../../../shared/components/Buttons";

export default function () {
  return (
    <Card>
      <CardBody style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div className="pe-3">
          <p className="mb-0">
            <strong>You are using a TNM Viewer account in guest mode.</strong>
          </p>
          <p className="mb-0">
            All information about your maps is stored in your browser and will be lost if
            you change your browser or clear your browsing history.
            Sign up to store maps in the service cloud and get access to additional features of TNM Viewer.
          </p>
        </div>
        
        <LinkButton to="/account/register" themeColor={'primary'}>Sign Up</LinkButton>
      </CardBody>
    </Card>
  );
}
