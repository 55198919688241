import { Color, SceneMode } from "cesium";
import { Project, TreeZone } from "../../models/ProjectModels";
import { MapperBase, MappingConfig, ZoneProps } from "../mapperBase";

class TreeZoneMapper extends MapperBase {
  map(project: Project, treeZones: TreeZone[], config: MappingConfig) {
    const alpha = config.opacity / 100;
    const props: ZoneProps = {
      ...this.defaultZoneProps,
      ...this.getLabelAndPointMaterials(alpha),
      showEntityLabels: config.showEntityLabels,
      showPointLabels: config.showPointLabels,
      material: Color.fromAlpha(Color.GREEN, alpha)
    };

    const mapped = treeZones.map(treeZone => {
      if (config.mapMode === SceneMode.SCENE3D) {
        props.extrudedHeight = treeZone.averageHeight;
      }

      return this.mapPolygon(
        treeZone.id, 
        treeZone.name, 
        treeZone.points, 
        props, 
        project);
    });

    return (
      <>
        {mapped}
      </>
    );
  }
}

export default new TreeZoneMapper();