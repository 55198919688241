import { configureStore } from "@reduxjs/toolkit";
import viewReducer from "./../projects/view/reducers/viewSlice";
import manageReducer from "./../projects/manage/reducers/manageSlice";

const store = configureStore({
  reducer: {
    manage: manageReducer,
    view: viewReducer
  }
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;