import { ChipList, ChipListChangeEvent } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { ProjectCollaborationType } from "../../models/ProjectsModel";

interface Tab {
  text: string;
  value: ProjectCollaborationType;
}

const tabs: Tab[] = [
  { text: "All projects", value: ProjectCollaborationType.any },
  { text: "Uploaded", value: ProjectCollaborationType.owned },
  { text: "Shared with me", value: ProjectCollaborationType.shared }
];

const Tabs = ({ onChange }: any) => {
  const [selectedTab, setIndex] = useState(tabs[0]);

  function handleTabChange(e: ChipListChangeEvent) {
    onChange(e.value);
  }

  return (
    <div className="presets d-flex justify-content-center">
      <ChipList data={tabs} value={selectedTab} selection="single" onChange={handleTabChange} />
    </div>
  )
};

export default Tabs;