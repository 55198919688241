import { Route } from "react-router";
import { Navigate } from "react-router-dom";
import ManageProjects from "../../manage/pages/ManageProjects";
import ViewProject from "../../view/pages/ViewProject";

export default function useProjectsRoutes() {
  return (
    <Route path="">
      <Route index element={<ManageProjects />}></Route>
      <Route path="view/:id?" element={<ViewProject />}></Route>
      <Route path="*" element={<Navigate to="" />} />
    </Route>
  )
}
