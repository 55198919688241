import { Navigate, Outlet } from "react-router";
import accountContextService from "../../account/services/accountContextService";

export function UnauthorizedRoute() {
  if (accountContextService.account) {
    return (<Navigate to={"/"} replace />);
  }

  return (<Outlet />);
}

export function AuthorizedRoute() {
  if (!accountContextService.account) {
    return (<Navigate to={"/account/login"} replace />);
  }

  return (<Outlet />);
}
