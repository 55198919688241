import { Window } from '@progress/kendo-react-dialogs';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/commonHooks';
import { EntityView, EntityViewStatus } from '../models/LegendModels';
import { changeViewEntitySetting } from '../reducers/viewSlice';
import LegendItem from './LegendItem';
import { Loader } from '@progress/kendo-react-indicators';

const Legend = () => {
  const views = useAppSelector(state => state.view.views);
  const dispatch = useAppDispatch();

  function isLoading() {
    return views.some(x => x.status === EntityViewStatus.loading);
  }

  const legendItems = views.map(x =>
    <LegendItem key={x.type}
      setting={x}
      handleShowClick={(e: EntityView) => dispatch(changeViewEntitySetting({ ...e, show: !e.show }))}
      handleOpacityChange={(e: EntityView, value: number) => dispatch(changeViewEntitySetting({ ...e, opacity: value }))}
      handleShowEntityLabelsClick={(e: EntityView) => dispatch(changeViewEntitySetting({ ...e, showEntityLabels: !e.showEntityLabels }))}
      handleShowPointLabelsClick={(e: EntityView) => dispatch(changeViewEntitySetting({ ...e, showPointLabels: !e.showPointLabels }))}
    />
  );

  return (
    <div>
      <Window
        title={'Legend'}
        initialLeft={10}
        initialTop={138}
        draggable={false}
        resizable={false}
        className='map-legend-window'
        closeButton={() => null}
        maximizeButton={() => null}
      >
        <div className='h-100'>
          {
            legendItems
          }

          {
            isLoading() &&
            (<div className='d-flex justify-content-center align-items-center'>
              <Loader />
            </div>)
          }
        </div>
      </Window>
    </div>
  )
}

export default Legend;