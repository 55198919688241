import { SvgIcon } from "@progress/kendo-react-common";
import { EntityType } from "../models/LegendModels";
import {
  barriersIcon,
  baseMapIcon,
  buildingRowsIcon,
  contourZonesIcon,
  groundZonesIcon,
  receiversIcon,
  roadwaysIcon,
  terrainLinesIcon,
  treeZonesIcon
} from "../../../shared/components/Icons";

function getIcon(entityType: EntityType) {
  const icons: any = {};
  icons[EntityType.baseMap] = baseMapIcon;
  icons[EntityType.barriers] = barriersIcon;
  icons[EntityType.receivers] = receiversIcon;
  icons[EntityType.buildingRows] = buildingRowsIcon;
  icons[EntityType.roadways] = roadwaysIcon;
  icons[EntityType.treeZones] = treeZonesIcon;
  icons[EntityType.contourZones] = contourZonesIcon;
  icons[EntityType.groundZones] = groundZonesIcon;
  icons[EntityType.terrainLines] = terrainLinesIcon;

  return icons[entityType];
}

const LegendIcon = (props: { entityType: EntityType, disabled: boolean }) => {
  return (
    <SvgIcon
      width={32} height={32}
      icon={getIcon(props.entityType)}
      className={props.disabled ? "text-disabled" : ""} />
  );
}

export default LegendIcon;