import { Cartesian2, Cartesian3, Color, Globe, HeightReference, PolylineArrowMaterialProperty, PolylineDashMaterialProperty, SceneMode, createGuid } from "cesium";
import projectionService from "../../../shared/services/projectionService";
import { PointBase, Project } from "../models/ProjectModels";
import { Entity, Label, LabelCollection, PointGraphics, PolygonGraphics, PolylineGraphics } from "resium";

export interface MappingConfig {
  mapMode: SceneMode,
  globe: Globe,
  opacity: number,
  showEntityLabels: boolean,
  showPointLabels: boolean
}

export interface ZoneProps {
  material: Color;
  pointsMaterial?: Color;
  pointsOutlineMaterial?: Color;
  pointsLabelMaterial?: Color;
  labelMaterial?: Color;
  showEntityLabels: boolean;
  showPointLabels: boolean;
  outline: boolean;
  outlineColor: Color;
  outlineWidth: number;
  height?: number;
  extrudedHeight?: number;
  heightReference: HeightReference;
}

export interface PolylineProps {
  id: string;
  name: string;
  points: PointBase[];
  width?: number;
  clampToGround?: boolean;
  labelPixelOffset: Cartesian2;
  polylineMaterial: PolylineDashMaterialProperty | PolylineArrowMaterialProperty;
  pointsMaterial?: Color;
  pointsOutlineMaterial?: Color;
  pointsLabelMaterial?: Color;
  labelMaterial: Color;
  showEntityLabels: boolean;
  showPointLabels: boolean;
}

export class MapperBase {

  protected readonly defaultLabelProps = {
    font: '20px sans-serif',
    fillColor: Color.BLACK,
    outline: true,
    outlineWidth: 1.5,
    outlineColor: Color.BLACK,
    scale: 0.8,
    heightReference: HeightReference.CLAMP_TO_GROUND
  };
  
  protected readonly defaultPointLabelProps = { ...this.defaultLabelProps, 
    pixelOffset: Cartesian2.fromElements(15, 15) };
  
  protected readonly defaultPointProps = {
    pixelSize: 8,
    color: Color.RED,
    outlineColor: Color.BLACK,
    outlineWidth: 3,
    heightReference: HeightReference.CLAMP_TO_GROUND
  };

  protected readonly defaultZoneProps: ZoneProps = {
    material: Color.GREEN,
    showEntityLabels: true,
    showPointLabels: true,
    outline: true,
    outlineColor: Color.GRAY,
    outlineWidth: 2,
    heightReference: HeightReference.CLAMP_TO_GROUND
  }

  protected mapPolyline(props: PolylineProps, project: Project) {
    const projectedPoints = this.projectPoints(project, props.points);

    const entityLabel = 
      <Label 
        key={props.id} 
        {...this.defaultLabelProps}
        fillColor={props.labelMaterial}
        position={projectedPoints[0]} 
        text={props.name} 
        pixelOffset={props.labelPixelOffset} 
      />;
    
    const pointsElements = this.mapPoints(
      props.points, 
      projectedPoints,
      props.showPointLabels,
      props.pointsLabelMaterial,
      props.pointsMaterial, 
      props.pointsOutlineMaterial);

    return (
      <>
        {pointsElements}
        <Entity key={props.id}>
          <PolylineGraphics 
            key={createGuid()} 
            clampToGround={props.clampToGround} 
            positions={projectedPoints} 
            width={props.width} 
            material={props.polylineMaterial} 
          />
          {props.showEntityLabels && <LabelCollection key={createGuid()}>
            {entityLabel}
          </LabelCollection>}
        </Entity>
      </>
    );
  }

  protected mapPolygon(
    id: string, 
    name: string, 
    entityPoints: PointBase[], 
    props: ZoneProps, 
    project: Project
  ) {
    const points = this.projectPoints(project, entityPoints);
    const pointElements = this.mapPoints(
      entityPoints, 
      points,
      props.showPointLabels,
      props.pointsLabelMaterial,
      props.pointsMaterial, 
      props.pointsOutlineMaterial);

    return (
      <>
        {pointElements}
        <Entity key={id}>
          <PolygonGraphics key={'polygongraphics-' + id} {...props} outline={false} hierarchy={points} />
        </Entity>
        {props.showEntityLabels && <LabelCollection key={createGuid()}>
          <Label
            key={'entitylabel-' + id}
            {...this.defaultLabelProps} 
            position={points[0]}
            fillColor={props.labelMaterial}
            text={name} 
            pixelOffset={new Cartesian2(30, -30)} 
          />
        </LabelCollection>}
      </>
    );
  }

  
  private mapPoints(
    entityPoints: PointBase[], 
    projectedPoints: Cartesian3[], 
    showLabels: boolean,
    pointLabelMaterial?: Color,
    pointColorMaterial?: Color,
    pointOutlineColorMaterial?: Color
  ): JSX.Element {
    const pointsElements = projectedPoints.map((point, index) =>
      <Entity key={'point-' + entityPoints[index].id} position={point}>
        <PointGraphics
          key={'pointgraphics-' + createGuid()}
          {...this.defaultPointProps}
          color={pointColorMaterial}
          outlineColor={pointOutlineColorMaterial}/>
      </Entity>);

    if (!showLabels) {
      return (
        <>
          {pointsElements}
        </>
      );
    }

    const pointsLabels = projectedPoints.map((point, index) =>
      <Label 
        key={'pointlabel-' + entityPoints[index].id} 
        {...this.defaultPointLabelProps} 
        position={point}
        text={entityPoints[index].name} 
        fillColor={pointLabelMaterial}
      />
    );

    return (
      <>
        {pointsElements}
        <LabelCollection key={createGuid()}>
          {pointsLabels}
        </LabelCollection>
      </>
    );
  }

  public projectPoints(project: Project, points: PointBase[]): Cartesian3[] {
    const transformedPoints = points.map(
      point => [...projectionService.projectToLatLong({...project}, point.theX, point.theY), point.theZ]);

    const cesiumPoints = transformedPoints.map(
      points => Cartesian3.fromDegrees(points[0], points[1], points[2]));

    return cesiumPoints;
  }

  public projectPointsLatLong(project: Project, points: PointBase[]): number[][] {
    const transformedPoints = points.map(
      point => [...projectionService.projectToLatLong({...project}, point.theX, point.theY), point.theZ]);
    return transformedPoints;
  }

  protected getLabelAndPointMaterials(opacity: number) {
    return {
      pointsMaterial: Color.fromAlpha(Color.RED, opacity),
      pointsOutlineMaterial: Color.fromAlpha(Color.BLACK, opacity),
      pointsLabelMaterial: Color.fromAlpha(Color.BLACK, opacity),
      labelMaterial: Color.fromAlpha(Color.BLACK, opacity)
    };
  }
}

export const mapperBase = new MapperBase();