import { Cartesian2, Color, PolylineDashMaterialProperty } from "cesium";
import { Project, TerrainLine } from "../../models/ProjectModels";
import { MapperBase, MappingConfig } from "../mapperBase";

class TerrainLineMapper extends MapperBase {
  map(project: Project, terrainLines: TerrainLine[], config: MappingConfig): JSX.Element {
    const alpha = config.opacity / 100;
    const mapped = terrainLines.map(x => this.mapPolyline(
      {
        ...x,
        ...this.getLabelAndPointMaterials(alpha),
        showEntityLabels: config.showEntityLabels,
        showPointLabels: config.showPointLabels,
        clampToGround: true,
        width: 5,
        labelPixelOffset: new Cartesian2(25, -30),
        polylineMaterial: new PolylineDashMaterialProperty({color: Color.fromAlpha(Color.GREEN, alpha)})
      }, 
      project)
    );

    return (
      <>
        {mapped}
      </>
    );
  }
}

export default new TerrainLineMapper();