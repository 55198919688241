import { SVGIcon } from "@progress/kendo-svg-icons";

export const d2Icon: SVGIcon = {
  name: "2D",
  content: "<svg width=\"22\" height=\"22\" viewBox=\"0 0 22 22\" fill=\"none\"><path d=\"M8 17L1 21V5L8 1M8 17L15 21M8 17V1M15 21L21 17V1L15 5M15 21V5M15 5L8 1\" stroke=\"#1F2933\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 22 22"
};

export const d3Icon: SVGIcon = {
  name: "3D",
  content: "<svg width=\"22\" height=\"22\" viewBox=\"0 0 22 22\" fill=\"none\"><path d=\"M11 1C13.5013 3.73835 14.9228 7.29203 15 11C14.9228 14.708 13.5013 18.2616 11 21M11 1C8.49872 3.73835 7.07725 7.29203 7 11C7.07725 14.708 8.49872 18.2616 11 21M11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21M11 1C16.5228 1 21 5.47715 21 11C21 16.5228 16.5228 21 11 21M1.50002 8H20.5M1.5 14H20.5\" stroke=\"#9AA5B1\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 22 22"
};

export const addProjectIcon: SVGIcon = {
  name: "Add Project",
  content: "<svg width=\"18\" height=\"22\" viewBox=\"0 0 18 22\" fill=\"none\"><path d=\"M11 1.26953V5.40007C11 5.96012 11 6.24015 11.109 6.45406C11.2049 6.64222 11.3578 6.7952 11.546 6.89108C11.7599 7.00007 12.0399 7.00007 12.6 7.00007H16.7305M9 17V11M6 14H12M11 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21H12.2C13.8802 21 14.7202 21 15.362 20.673C15.9265 20.3854 16.3854 19.9265 16.673 19.362C17 18.7202 17 17.8802 17 16.2V7L11 1Z\" stroke=\"#212529\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 18 22"
};

export const homeIcon: SVGIcon = {
  name: "Home",
  content: "<svg width=\"21\" height=\"21\" viewBox=\"0 0 21 21\" fill=\"none\"><path d=\"M11.4823 1.764C11.131 1.49075 10.9553 1.35412 10.7613 1.3016C10.5902 1.25526 10.4098 1.25526 10.2387 1.3016C10.0447 1.35412 9.86902 1.49075 9.5177 1.764L2.73539 7.03912C2.28202 7.39175 2.05534 7.56806 1.89203 7.78886C1.74737 7.98444 1.6396 8.20478 1.57403 8.43905C1.5 8.70352 1.5 8.9907 1.5 9.56505V16.8C1.5 17.9201 1.5 18.4801 1.71799 18.908C1.90973 19.2843 2.21569 19.5903 2.59202 19.782C3.01984 20 3.5799 20 4.7 20H6.7C6.98003 20 7.12004 20 7.227 19.9455C7.32108 19.8976 7.39757 19.8211 7.4455 19.727C7.5 19.62 7.5 19.48 7.5 19.2V12.6C7.5 12.0399 7.5 11.7599 7.60899 11.546C7.70487 11.3578 7.85785 11.2049 8.04601 11.109C8.25992 11 8.53995 11 9.1 11H11.9C12.4601 11 12.7401 11 12.954 11.109C13.1422 11.2049 13.2951 11.3578 13.391 11.546C13.5 11.7599 13.5 12.0399 13.5 12.6V19.2C13.5 19.48 13.5 19.62 13.5545 19.727C13.6024 19.8211 13.6789 19.8976 13.773 19.9455C13.88 20 14.02 20 14.3 20H16.3C17.4201 20 17.9802 20 18.408 19.782C18.7843 19.5903 19.0903 19.2843 19.282 18.908C19.5 18.4801 19.5 17.9201 19.5 16.8V9.56505C19.5 8.9907 19.5 8.70352 19.426 8.43905C19.3604 8.20478 19.2526 7.98444 19.108 7.78886C18.9447 7.56806 18.718 7.39175 18.2646 7.03913L11.4823 1.764Z\" stroke=\"#616E7C\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 21 21"
};

export const magicWandIcon: SVGIcon = {
  name: "Magic Wand",
  content: "<svg width=\"22\" height=\"21\" viewBox=\"0 0 22 21\" fill=\"none\"><path d=\"M13.5 3V1M13.5 15V13M6.5 8H8.5M18.5 8H20.5M16.3 10.8L17.5 12M16.3 5.2L17.5 4M1.5 20L10.5 11M10.7 5.2L9.5 4\" stroke=\"#616E7C\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 22 21"
};

export const editIcon: SVGIcon = {
  name: "Edit",
  content: "<svg width=\"22\" height=\"22\" viewBox=\"0 0 22 22\" fill=\"none\"><path d=\"M1.5 20.5L7.04927 18.3657C7.40421 18.2291 7.58168 18.1609 7.74772 18.0718C7.8952 17.9926 8.0358 17.9012 8.16804 17.7986C8.31692 17.6831 8.45137 17.5486 8.72028 17.2797L20 6C21.1046 4.89543 21.1046 3.10457 20 2C18.8955 0.895428 17.1046 0.895427 16 1.99999L4.72028 13.2797C4.45138 13.5486 4.31692 13.6831 4.20139 13.832C4.09877 13.9642 4.0074 14.1048 3.92823 14.2523C3.83911 14.4183 3.77085 14.5958 3.63433 14.9507L1.5 20.5ZM1.5 20.5L3.55812 15.149C3.7054 14.766 3.77903 14.5746 3.90534 14.4869C4.01572 14.4102 4.1523 14.3813 4.2843 14.4065C4.43533 14.4353 4.58038 14.5804 4.87048 14.8705L7.12957 17.1295C7.41967 17.4196 7.56472 17.5647 7.59356 17.7157C7.61877 17.8477 7.58979 17.9843 7.51314 18.0947C7.42545 18.221 7.23399 18.2946 6.85107 18.4419L1.5 20.5Z\" stroke=\"#616E7C\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 22 22"
};

export const exportIcon: SVGIcon = {
  name: "Export",
  content: "<svg width=\"21\" height=\"21\" viewBox=\"0 0 21 21\" fill=\"none\"><path d=\"M11.4823 1.76403C11.131 1.49078 10.9553 1.35415 10.7613 1.30163C10.5902 1.25529 10.4098 1.25529 10.2387 1.30163C10.0447 1.35415 9.86902 1.49078 9.5177 1.76403L2.73539 7.03916C2.28202 7.39178 2.05534 7.56809 1.89203 7.78889C1.74737 7.98447 1.6396 8.20481 1.57403 8.43908C1.5 8.70355 1.5 8.99073 1.5 9.56508V16.8C1.5 17.9201 1.5 18.4802 1.71799 18.908C1.90973 19.2843 2.21569 19.5903 2.59202 19.782C3.01984 20 3.5799 20 4.7 20H6.7C6.98003 20 7.12004 20 7.227 19.9455C7.32108 19.8976 7.39757 19.8211 7.4455 19.727C7.5 19.6201 7.5 19.48 7.5 19.2V12.6C7.5 12.04 7.5 11.7599 7.60899 11.546C7.70487 11.3579 7.85785 11.2049 8.04601 11.109C8.25992 11 8.53995 11 9.1 11H11.9C12.4601 11 12.7401 11 12.954 11.109C13.1422 11.2049 13.2951 11.3579 13.391 11.546C13.5 11.7599 13.5 12.04 13.5 12.6V19.2C13.5 19.48 13.5 19.6201 13.5545 19.727C13.6024 19.8211 13.6789 19.8976 13.773 19.9455C13.88 20 14.02 20 14.3 20H16.3C17.4201 20 17.9802 20 18.408 19.782C18.7843 19.5903 19.0903 19.2843 19.282 18.908C19.5 18.4802 19.5 17.9201 19.5 16.8V9.56508C19.5 8.99073 19.5 8.70355 19.426 8.43908C19.3604 8.20481 19.2526 7.98447 19.108 7.78889C18.9447 7.56809 18.718 7.39178 18.2646 7.03916L11.4823 1.76403Z\" stroke=\"#616E7C\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 21 21"
};

export const shareIcon: SVGIcon = {
  name: "Share",
  content: "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\"><path d=\"M19 4H15.8C14.1198 4 13.2798 4 12.638 4.32698C12.0735 4.6146 11.6146 5.07354 11.327 5.63803C11 6.27976 11 7.11984 11 8.8V10M19 4L16 1M19 4L16 7M8 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V14.2C1 15.8802 1 16.7202 1.32698 17.362C1.6146 17.9265 2.07354 18.3854 2.63803 18.673C3.27976 19 4.11984 19 5.8 19H14.2C15.8802 19 16.7202 19 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C19 16.7202 19 15.8802 19 14.2V12\" stroke=\"#1F2933\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 20 20"
};

export const viewIcon: SVGIcon = {
  name: "View",
  content: "<svg width=\"22\" height=\"21\" viewBox=\"0 0 22 21\" fill=\"none\"><path d=\"M13.5 3V1M13.5 15V13M6.5 8H8.5M18.5 8H20.5M16.3 10.8L17.5 12M16.3 5.2L17.5 4M1.5 20L10.5 11M10.7 5.2L9.5 4\" stroke=\"#01337D\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 22 21"
};

export const zoomInIcon: SVGIcon = {
  name: "Zoom In",
  content: "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\"><path d=\"M19 19L14.65 14.65M9 6V12M6 9H12M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z\" stroke=\"#1F2933\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 20 20"
};

export const zoomOutIcon: SVGIcon = {
  name: "Zoom Out",
  content: "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\"><path d=\"M19 19L14.65 14.65M6 9H12M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z\" stroke=\"#1F2933\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 20 20"
};

export const eyeIcon: SVGIcon = {
  name: "Eye",
  content: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /><path d=\"M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 24 24"
};

export const eyeSlashIcon: SVGIcon = {
  name: "Eye Slash",
  content: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\"><path d=\"M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 24 24"
};

export const baseMapIcon: SVGIcon = {
  name: "Base Map",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><path d=\"M16 6L32 42M6 34L24 24M15.6 42H32.4C35.7603 42 37.4405 42 38.7239 41.346C39.8529 40.7708 40.7708 39.8529 41.346 38.7239C42 37.4405 42 35.7603 42 32.4V15.6C42 12.2397 42 10.5595 41.346 9.27606C40.7708 8.14708 39.8529 7.2292 38.7239 6.65396C37.4405 6 35.7603 6 32.4 6H15.6C12.2397 6 10.5595 6 9.27606 6.65396C8.14708 7.2292 7.2292 8.14708 6.65396 9.27606C6 10.5595 6 12.2397 6 15.6V32.4C6 35.7603 6 37.4405 6.65396 38.7239C7.2292 39.8529 8.14708 40.7708 9.27606 41.346C10.5595 42 12.2397 42 15.6 42Z\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 48 48"
};

export const treeZonesIcon: SVGIcon = {
  name: "Tree Zones",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><g clip-path=\"url(#clip0_270_15523)\"><path d=\"M17.598 25.598L17.598 65.196M30.3259 32.6691L17.598 45.397M19.8607 54.448L29.3886 44.9201C29.7345 44.5742 29.9075 44.4012 30.0312 44.1994C30.1408 44.0204 30.2216 43.8253 30.2706 43.6213C30.3259 43.3911 30.3259 43.1465 30.3259 42.6573L30.3259 28.4265C30.3259 27.7506 30.3259 27.4126 30.3123 27.127C30.0177 20.9489 25.0755 16.0067 18.8974 15.7121C18.6118 15.6985 18.2739 15.6985 17.598 15.6985C16.9221 15.6985 16.5841 15.6985 16.2986 15.7121C10.1204 16.0067 5.17824 20.9489 4.88367 27.127C4.87006 27.4126 4.87006 27.7506 4.87006 28.4264L4.87006 42.6573C4.87006 43.1465 4.87006 43.3911 4.92532 43.6213C4.97431 43.8253 5.05512 44.0204 5.16478 44.1994C5.28846 44.4012 5.46141 44.5742 5.80732 44.9201L15.3352 54.448C16.1273 55.24 16.5233 55.636 16.9799 55.7844C17.3816 55.9149 17.8143 55.9149 18.216 55.7844C18.6727 55.636 19.0687 55.24 19.8607 54.448Z\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /><path d=\"M33.598 11.598L33.598 51.196M34.216 41.7844C34.6727 41.636 35.0687 41.24 35.8607 40.448L45.3886 30.9201C45.7345 30.5742 45.9075 30.4012 46.0312 30.1994C46.1408 30.0204 46.2216 29.8253 46.2706 29.6213C46.3259 29.3911 46.3259 29.1465 46.3259 28.6573L46.3259 14.4265C46.3259 13.7506 46.3259 13.4126 46.3123 13.127C46.0177 6.94889 41.0755 2.00671 34.8974 1.71214C34.6118 1.69853 34.2739 1.69853 33.598 1.69853C32.9221 1.69853 32.5841 1.69853 32.2986 1.71214C26.1204 2.00671 21.1782 6.94889 20.8837 13.127C20.8701 13.4126 20.8701 13.7506 20.8701 14.4264\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></g><defs><clipPath id=\"clip0_270_15523\"><rect width=\"48\" height=\"48\" fill=\"white\" /></clipPath></defs></svg>",
  viewBox: "0 0 48 48"
};

export const groundZonesIcon: SVGIcon = {
  name: "Ground Zones",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><path d=\"M24.7155 14.3578C24.4532 14.2266 24.322 14.161 24.1844 14.1352C24.0625 14.1123 23.9375 14.1123 23.8156 14.1352C23.678 14.161 23.5468 14.2266 23.2845 14.3578L4 24L23.2845 33.6422C23.5468 33.7734 23.678 33.839 23.8156 33.8648C23.9375 33.8877 24.0625 33.8877 24.1844 33.8648C24.322 33.839 24.4532 33.7734 24.7155 33.6422L44 24L24.7155 14.3578Z\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 48 48"
};

export const contourZonesIcon: SVGIcon = {
  name: "Contour Zones",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><path d=\"M6 6H6.02M6 24H6.02M6 42H6.02M6 33H6.02M6 15H6.02M15 6H15.02M15 24H15.02M15 42H15.02M33 6H33.02M33 24H33.02M33 42H33.02M24 6H24.02M24 24H24.02M24 42H24.02M24 33H24.02M24 15H24.02M42 6H42.02M42 24H42.02M42 42H42.02M42 33H42.02M42 15H42.02\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 48 48"
};

export const roadwaysIcon: SVGIcon = {
  name: "Roadways",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><path d=\"M23 10H23.8688C29.9633 10 33.0105 10 34.1673 11.0946C35.1672 12.0407 35.6103 13.4346 35.3403 14.7844C35.028 16.346 32.5402 18.1057 27.5646 21.6251L19.4354 27.3749C14.4598 30.8943 11.972 32.654 11.6597 34.2156C11.3897 35.5654 11.8328 36.9593 12.8327 37.9054C13.9895 39 17.0367 39 23.1312 39H25M16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10ZM44 38C44 41.3137 41.3137 44 38 44C34.6863 44 32 41.3137 32 38C32 34.6863 34.6863 32 38 32C41.3137 32 44 34.6863 44 38Z\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 48 48"
};

export const barriersIcon: SVGIcon = {
  name: "Barriers",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><path d=\"M10.5 40V31.5H6V28.5H10.5V23.5H6V20.5H10.5V13.5L16 8L20 12L24.05 8L28.05 12L32.05 8L37.55 13.5V20.5H42V23.5H37.55V28.5H42V31.5H37.55V40H10.5ZM13.5 20.5H18.5V14.75L16 12.25L13.5 14.75V20.5ZM21.5 20.5H26.5V14.75L24 12.25L21.5 14.75V20.5ZM29.55 20.5H34.5V14.75L32 12.25L29.55 14.7V20.5ZM13.5 28.5H18.5V23.5H13.5V28.5ZM21.5 28.5H26.5V23.5H21.5V28.5ZM29.55 28.5H34.5V23.5H29.55V28.5ZM13.5 37H18.5V31.5H13.5V37ZM21.5 37H26.5V31.5H21.5V37ZM29.55 37H34.5V31.5H29.55V37Z\" fill=\"#000\" /></svg>",
  viewBox: "0 0 48 48"
};
export const buildingRowsIcon: SVGIcon = {
  name: "Building Rows",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><path d=\"M6 42V14.1H18.35V9.5L24 4L29.65 9.5V22.4H42V42H6ZM9 39H14.3V33.7H9V39ZM9 30.7H14.3V25.4H9V30.7ZM9 22.4H14.3V17.1H9V22.4ZM21.35 39H26.65V33.7H21.35V39ZM21.35 30.7H26.65V25.4H21.35V30.7ZM21.35 22.4H26.65V17.1H21.35V22.4ZM21.35 14.1H26.65V8.8H21.35V14.1ZM33.7 39H39V33.7H33.7V39ZM33.7 30.7H39V25.4H33.7V30.7Z\" fill=\"#000\" /></svg>",
  viewBox: "0 0 48 48"
};

export const terrainLinesIcon: SVGIcon = {
  name: "Terrain Lines",
  content: "<svg width=\"42\" height=\"44\" viewBox=\"0 0 42 44\" fill=\"none\"><path d=\"M0.0750122 43.925H39.875L27.975 28.05L17.975 24.05L11.975 16.05H0.0750122V43.925ZM2.95001 41.05V35.85L8.00001 37.475L26.75 31.275L34.125 41.05H2.95001ZM8.00001 34.5L2.95001 32.8V27.85L8.00001 29.475L16.675 26.675L22.925 29.5L8.00001 34.5ZM33.025 27.925L41.95 23.95V16.05L33.025 14.05L28.075 18.025V23.975L33.025 27.925ZM8.00001 26.5L2.95001 24.8V18.925H10.525L14.725 24.275L8.00001 26.5ZM33.4 25.175L30.45 22.875V19.175L33.65 16.625L39.575 17.95V22.375L33.4 25.175ZM20.025 15.925L29.95 11.95V2.05005L20.025 0.0500488L14.075 4.02505V11.95L20.025 15.925ZM20.25 13.275L16.45 10.675V5.30005L20.55 2.57505L27.575 4.00005V10.325L20.25 13.275Z\" fill=\"#000\" /></svg>",
  viewBox: "0 0 48 48"
};

export const receiversIcon: SVGIcon = {
  name: "Receivers",
  content: "<svg width=\"48\" height=\"48\" viewBox=\"0 0 48 48\" fill=\"none\"><path d=\"M40 24C40 32.8366 32.8366 40 24 40M40 24C40 15.1634 32.8366 8 24 8M40 24H44M24 40C15.1634 40 8 32.8366 8 24M24 40V44M8 24C8 15.1634 15.1634 8 24 8M8 24H4M24 8V4M30 24C30 27.3137 27.3137 30 24 30C20.6863 30 18 27.3137 18 24C18 20.6863 20.6863 18 24 18C27.3137 18 30 20.6863 30 24Z\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></svg>",
  viewBox: "0 0 48 48"
};

export const textIcon: SVGIcon = {
  name: "Text",
  content: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M4 7C4 6.06812 4 5.60218 4.15224 5.23463C4.35523 4.74458 4.74458 4.35523 5.23463 4.15224C5.60218 4 6.06812 4 7 4H17C17.9319 4 18.3978 4 18.7654 4.15224C19.2554 4.35523 19.6448 4.74458 19.8478 5.23463C20 5.60218 20 6.06812 20 7M9 20H15M12 4V20\" stroke=\"black\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 24 24"
};

export const textSlashIcon: SVGIcon = {
  name: "Text",
  content: "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M4 7C4 6.06812 4 5.60218 4.15224 5.23463C4.35523 4.74458 4.74458 4.35523 5.23463 4.15224C5.60218 4 6.06812 4 7 4H17C17.9319 4 18.3978 4 18.7654 4.15224C19.2554 4.35523 19.6448 4.74458 19.8478 5.23463C20 5.60218 20 6.06812 20 7M9 20H15M12 4V20\" stroke=\"black\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M19.8054 19.8066L20.0096 20.272M22 22L17 17L12 12L2 2\" stroke=\"black\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></svg>",
  viewBox: "0 0 24 24"
};
