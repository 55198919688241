import { AxiosProgressEvent } from "axios";
import { axios } from "../../../shared/api/axios";
import { ProjectCollaborationType, ProjectsListItem } from "../models/ProjectsModel";

export interface GetProjectsResponse {
  data: ProjectsListItem[];
}

class ProjectsService {
  getProjects(collaborationType?: ProjectCollaborationType): Promise<GetProjectsResponse> {
    return axios.get('/projects', {params: { collaborationType }});
  }

  deleteProject(id: string): Promise<any> {
    return axios.delete(`/projects/${id}`);
  }

  uploadProject(
    file: FormData,
    abortController: AbortController,
    onUploadProgress: (e: AxiosProgressEvent) => void
  ): Promise<any> {
    return axios.post('/projects', file, {
      headers: {
        "Content-Type" : "multipart/form-data"
      },
      signal: abortController.signal,
      onUploadProgress: onUploadProgress
    });
  }
}

export default new ProjectsService();