import { ChangeEvent, FC, useRef, useState } from 'react'
import UploadProjectDialog from '../UploadProjectDialog/UploadProjectDialog';

interface UploadProjectProps {
  renderUploadButton: (handleClick: () => void) => any;
  onSuccessfulUpload: () => void;
}

const UploadProject: FC<UploadProjectProps> = ({ renderUploadButton, onSuccessfulUpload: onSuccessfulUpload }) => {

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleUploadClick() {
    inputRef.current?.click();
  };

  function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);
    setShowDialog(true);
  };

  function handleCloseDialog() {
    setShowDialog(false);
    onSuccessfulUpload();
  }

  return (
    <div>
      {renderUploadButton(handleUploadClick)}

      <input
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {showDialog && (<UploadProjectDialog file={file} onCloseDialog={handleCloseDialog} />)}
    </div>
  );
}

export default UploadProject;