const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);

export function requiredValidator(value: any) {
  return value ? "" : "This field is required";
}

export function emailValidator(value: any) {
  return emailRegex.test(value) ? "" : "Email is not in a valid format";
}

export function passwordValidator(value: any) {
  return passwordRegex.test(value) ? "" : "Minimum 8 characters, at least one uppercase, one lowercase, one number and one special character"
}

export function passwordsMatchValidator(value: any, secondValue: any) {
  return value === secondValue ? "" : "Passwords do not match";
}
