export interface CoordinateSystemInfo {
  coordinateSystemType: string;
  coordinateSystemCategory: string;
  coordinateSystem: string;
}

export enum CoordinateSystemType {
  projected = 'Projected',
  geographical = 'Geographical',
  cartesian = 'Cartesian'
}