import { Cartesian2, Cartesian3, Color, HeightReference, createGuid } from "cesium";
import { Project, Receiver } from "../../models/ProjectModels";
import { MapperBase, MappingConfig } from "../mapperBase";
import { BoxGraphics, Entity, Label, LabelCollection } from "resium";

class ReceiverMapper extends MapperBase {
  map(project: Project, receivers: Receiver[], config: MappingConfig): JSX.Element {
    const mapped = receivers.map(x => this.mapReceiver(x, project, config));

    return (
      <>
        {mapped}
      </>
    );
  }

  private mapReceiver(receiver: Receiver, project: Project, config: MappingConfig) {
    const points = this.projectPoints(project, receiver.points);
    const alpha = config.opacity / 100;
    const material = Color.fromAlpha(Color.BLUE, alpha);
    const labelMaterial = Color.fromAlpha(Color.BLACK, alpha);

    return (
      <>
        <Entity key={receiver.id} position={points[0]}>
          <BoxGraphics 
            key={createGuid()} 
            heightReference={HeightReference.CLAMP_TO_GROUND} 
            material={material} 
            dimensions={new Cartesian3(2, 2, 2)}
          />
        </Entity>

        {config.showEntityLabels && <LabelCollection key={receiver.name + receiver.id}>
          <Label
            key={createGuid()}
            {...this.defaultLabelProps} 
            position={points[0]} 
            text={receiver.name}
            fillColor={labelMaterial}
            pixelOffset={new Cartesian2(15, 0)} 
          />
        </LabelCollection>}
      </>
    );
  }
}

export default new ReceiverMapper();