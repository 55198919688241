import './App.scss';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from './shared/components/Layout';
import useAccountRoutes from './account/hooks/useAccountRoutes';
import useProjectsRoutes from './projects/common/hooks/useProjectsRoutes';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="projects">{useProjectsRoutes()}</Route>
          <Route path="account">{useAccountRoutes()}</Route>
          <Route path="" element={<Navigate to="projects" />} />
          <Route path="*" element={<Navigate to="" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
