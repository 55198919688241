import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Card, CardHeader, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { AppNotification, FormNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { FormInput } from "../../shared/components/Inputs";
import { emailValidator, requiredValidator } from "../../shared/validators";
import accountService from "../services/accountService";

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [tempModel, setTempModel] = useState({});
  const [formNotificationMessage, setFormNotificationMessage] = useState<NotificationMessage>();
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();

  const emailValidators = [requiredValidator, emailValidator];

  async function handleSubmit(model: any) {
    const { userName } = model;
    setTempModel({ userName });

    setIsLoading(true);

    try {
      await accountService.forgotPassword(model);
      setNotificationMessage(NotificationMessage.success("Reset password link has been sent to your email"));
    } catch (error: any) {
      setFormNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  function handleInputChange() {
    setFormNotificationMessage(undefined);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="primary-card col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle className="heading-1 text-primary">Restore Password</CardTitle>
        </CardHeader>
        <CardBody>
          <FormNotification className="mb-45" notificationMessage={formNotificationMessage} />

          <Form
            initialValues={tempModel}
            onSubmit={handleSubmit}
            render={(props) => (
              <FormElement id={"form"}>
                <Field name={"userName"} type={"Email"} component={FormInput} label={"Email"}
                  validator={emailValidators} onChange={handleInputChange} />
              </FormElement>
            )}
          />

          <div className="mt-45 d-flex justify-content-between align-items-center">
            <Button themeColor={"primary"} type={"submit"} form={"form"}>Send restore link</Button>
            <Link to="../login"><strong>Back to login</strong></Link>
          </div>
        </CardBody>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
