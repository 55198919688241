import { FC, useEffect, useRef, useState } from 'react'

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from '@progress/kendo-react-buttons';
import { ArcGauge } from "@progress/kendo-react-gauges";

import projectsService from '../../api/projectsService';
import { AxiosProgressEvent } from 'axios';

enum UploadingProjectStage {
  uploadingFile = 1,
  processingProject = 2,
  successfulUpload = 3,
  error = 4
}

interface UploadProjectDialogProps {
  file?: File;
  onCloseDialog: () => void;
}

const UploadProjectDialog: FC<UploadProjectDialogProps> = ({ file, onCloseDialog }) => {

  const [uploadingStage, setUploadingStage] = useState(UploadingProjectStage.uploadingFile);
  const [progress, setProgress] = useState(0);
  const [abortController] = useState(new AbortController());
  const timerIdRef = useRef<any>();

  useEffect(() => {
    const formData = new FormData();
    formData.append('file', file as File);

    async function uploadProject() {
      try {
        await projectsService.uploadProject(formData, abortController, updateProgress);

        setUploadingStage(UploadingProjectStage.successfulUpload);
        setProgress(100);
        clearInterval();

      } catch (error: any) {
        setUploadingStage(UploadingProjectStage.error);
        clearInterval();
      }
    }

    uploadProject();
  }, []);

  function updateProgress(e: AxiosProgressEvent) {
    if (e.total) {
      const progress = Math.floor((e.loaded / e.total) * 60);
      setProgress(progress);

      if (e.loaded === e.total) {
        setUploadingStage(UploadingProjectStage.processingProject);
        startImitatingLoading();
      }
    }
  }

  function handleCancelClick() {
    abortController.abort();
    onCloseDialog();
  }

  function handleOkClick() {
    onCloseDialog();
  }

  function startImitatingLoading() {
    timerIdRef.current = window.setInterval(() => {
      setProgress(progress => (progress < 99 ? progress + 1 : 99));
    }, 1000);
  }

  function clearInterval() {
    window.clearInterval(timerIdRef.current);
    timerIdRef.current = undefined;
  }

  function arcCenterRenderer(value: any) {
    return (
      <h3 style={{ color: "#01337D" }}>
        {value}%
      </h3>
    );
  }

  return (
    <Dialog width={350}>
      <div style={{ margin: "-1.5rem 1rem -1rem 1rem" }}>
        <ArcGauge value={progress} arcCenterRender={arcCenterRenderer}
          scale={{ rangeSize: 15 }} color={"#01337D"} style={{ width: "100%" }} />
      </div>

      <p className={"m-0 text-center"} style={{minHeight: "3rem"}}>
        {getText(uploadingStage)}
      </p>

      <DialogActionsBar layout={'center'}>
        <Button themeColor={'primary'} fillMode={'outline'}
          onClick={showOkButton(uploadingStage) ? handleOkClick : handleCancelClick}>
          {showOkButton(uploadingStage) ? ('Close') : ('Cancel Upload')}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}

function getText(uploadingStage: UploadingProjectStage) {
  switch (uploadingStage) {
    case UploadingProjectStage.uploadingFile:
    case UploadingProjectStage.processingProject:
      return "Your project is being processed, please wait...";

    case UploadingProjectStage.successfulUpload:
      return "Your project has been successfully uploaded."

    case UploadingProjectStage.error:
      return "Error has been occured while uploading project."

    default:
      break;
  }
}

function showOkButton(uploadingStage: UploadingProjectStage) {
  switch (uploadingStage) {
    case UploadingProjectStage.successfulUpload:
    case UploadingProjectStage.error:
      return true;

    default:
      return false;
  }
}

export default UploadProjectDialog;