import { AppBarSection } from '@progress/kendo-react-layout';
import { AppBarSpacer } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { addProjectIcon } from '../../../shared/components/Icons';
import { useAppDispatch } from '../../../shared/hooks/commonHooks';
import { useCurrentPath } from '../hooks/useCurrentPath';
import UploadProject from '../../manage/components/UploadProject/UploadProject';
import { fetchProjectsList } from '../../manage/reducers/manageSlice';

const manageRoute = [{ path: '/projects' }];

const ManageAppBar = () => {
  const currentRoute = useCurrentPath(manageRoute);
  const dispatch = useAppDispatch();

  function handleSuccessfulUpload() {
    dispatch(fetchProjectsList());
  }

  return (
    <>
      {currentRoute &&
        <>
          <AppBarSection>
            <h2 className="heading-2 mb-0">Your Projects</h2>
          </AppBarSection>

          <AppBarSpacer />

          <AppBarSection className={"actions-appbar-section"}>
            <UploadProject
              renderUploadButton={handleClick => (
                <Button
                  fillMode={'flat'}
                  svgIcon={addProjectIcon}
                  onClick={handleClick}>
                  Upload New Project
                </Button>
              )}
              onSuccessfulUpload={handleSuccessfulUpload} />
          </AppBarSection>
        </>
      }
    </>
  )
}

export default ManageAppBar;
