import { Loader } from "@progress/kendo-react-indicators";

export function AppLoader(props: any) {
  const { size = "large" } = props;

  return (
    <div className="d-flex justify-content-center align-items-center h-100 mt-5">
      <Loader size={size} type={'converging-spinner'} />
    </div>
  );
}
