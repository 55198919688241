import { Cartesian3 } from "cesium";
import projectionService from "../../../shared/services/projectionService";
import { PointBase, Project } from "../models/ProjectModels";

const CAMERA_HEIGHT = 2000;

class CameraPointService {
  public transformCameraPoint(project: Project, point: PointBase) {
    const projected = projectionService.projectToLatLong({...project}, point.theX, point.theY);
    return Cartesian3.fromDegrees(projected[0], projected[1], CAMERA_HEIGHT);
  }
}

export default new CameraPointService();