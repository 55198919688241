import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody } from "@progress/kendo-react-layout";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { AppNotification, FormNotification, NotificationMessage } from "../../shared/components/Notifications";
import { AppLoader } from "../../shared/components/Indicators";
import { FormInput, PasswordField } from "../../shared/components/Inputs";
import { emailValidator, passwordValidator, passwordsMatchValidator, requiredValidator } from "../../shared/validators";
import { LoginModel, RegisterModel } from "../models/AccountModel";
import accountService from "../services/accountService";
import accountContextService from "../services/accountContextService";
import { claimProjects } from "../../projects/common/api/claimProjectsApi";

export default function Register() {
  const [isLoading, setIsLoading] = useState(false);
  const [tempModel, setTempModel] = useState({});
  const [formNotificationMessage, setFormNotificationMessage] = useState<NotificationMessage>();
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();
  const navigate = useNavigate();

  const emailValidators = [requiredValidator, emailValidator];
  const passwordValidators = [requiredValidator, passwordValidator];
  const confirmPasswordValidators = [
    requiredValidator,
    passwordValidator,
    (value: any, getter: (field: string) => any) => passwordsMatchValidator(value, getter("password"))
  ];

  async function handleSubmit(model: RegisterModel) {
    const { firstName, lastName, userName } = model;
    setTempModel({ firstName, lastName, userName });

    setIsLoading(true);

    try {
      model.email = model.userName;
      await accountService.register(model);
      setNotificationMessage(NotificationMessage.success("Congratulations! You have beed successfully registered!"));

      const loginModel: LoginModel = {
        userName: model.userName,
        password: model.password
      };
      const loginResult = await accountService.login(loginModel);
      accountContextService.finishLogin(loginResult);

      const guestCode = accountContextService.guestCode;
      if (guestCode) {
        await claimProjects(guestCode);
      }

      navigate("/");
    }
    catch (error: any) {
      setFormNotificationMessage(NotificationMessage.error(error));
    }

    setIsLoading(false);
  }

  function handleInputChange() {
    setFormNotificationMessage(undefined);
  }

  if (isLoading) {
    return (<AppLoader />);
  }

  return (
    <section className="container my-5">
      <Card className="primary-card col-md-6 offset-md-3">
        <CardHeader>
          <CardTitle className="heading-1 text-primary">Signup</CardTitle>
        </CardHeader>
        <CardBody>
          <FormNotification className="mb-45" notificationMessage={formNotificationMessage} />

          <Form
            initialValues={tempModel}
            onSubmit={data => handleSubmit(data as RegisterModel)}
            render={(props) => (
              <FormElement id={"form"}>
                <Field name={"firstName"} type={"Text"} component={FormInput} label={"First Name"}
                  validator={requiredValidator} onChange={handleInputChange} />

                <Field name={"lastName"} type={"Text"} component={FormInput} label={"Last Name"}
                  validator={requiredValidator} onChange={handleInputChange} />

                <Field name={"userName"} type={"Email"} component={FormInput} label={"Email"}
                  validator={emailValidators} onChange={handleInputChange} />

                <PasswordField name={"password"} label={"Password"}
                  validator={passwordValidators} onChange={handleInputChange} />

                <PasswordField name={"confirmPassword"} label={"Confirm Password"}
                  validator={confirmPasswordValidators} onChange={handleInputChange} />
              </FormElement>
            )}
          />

          <div className="mt-45 d-flex justify-content-between align-items-center">
            <Button themeColor={"primary"} type={"submit"} form={"form"}>Create new account</Button>
          </div>

          <div className="mt-45">
            <span className="text-primary">
              Already have an account? <Link to="../login"><strong>Login here</strong></Link>
            </span>
          </div>
        </CardBody>
      </Card>

      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </section>
  );
}
