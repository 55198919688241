import { Fade } from "@progress/kendo-react-animation";
import { NotificationGroup, Notification } from "@progress/kendo-react-notification";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

class ApiError {
  static DEFAULT_ERROR_MESSAGE = 'Oops, something went wrong';
  static ACCESS_DENIED_ERROR_MESSAGE = 'Access denied';
  static TOKEN_EXPIRED_ERROR_MESSAGE = 'Your session has run out, please log in again';

  message!: string;
  code?: number;

  constructor(message: string, code?: number) {
    this.message = message;
    this.code = code;
  }

  static create(error: any): ApiError {
    if (!error) {
      return new ApiError(this.DEFAULT_ERROR_MESSAGE);
    }

    if (typeof error === "string") {
      return new ApiError(error);
    }

    const axiosError = error as AxiosError;

    if (axiosError.response) {
      if (axiosError.response.data) {
        if (typeof axiosError.response.data === "string") {
          return new ApiError(axiosError.response.data);
        }

        return axiosError.response.data as ApiError;
      }

      if (axiosError.response.status === 403) {
        return new ApiError(this.ACCESS_DENIED_ERROR_MESSAGE);
      }

      if (axiosError.response.status === 401) {
        return new ApiError(this.TOKEN_EXPIRED_ERROR_MESSAGE, 401);
      }
    }

    if (axiosError.message) {
      return new ApiError(axiosError.message);
    }

    return new ApiError(this.DEFAULT_ERROR_MESSAGE);
  }
}

export class NotificationMessage {
  style?: "success" | "error"
  message?: string;
  errorCode?: number;

  static success(message: string): NotificationMessage {
    return { style: "success", message: message };
  }

  static error(error: any): NotificationMessage {
    const apiError = ApiError.create(error);
    return { style: "error", message: apiError.message, errorCode: apiError.code };
  }
}

export interface AppNotificationProps {
  notificationMessage?: NotificationMessage;
}

export function AppNotification({ notificationMessage }: AppNotificationProps) {
  const [message, setMessage] = useState<NotificationMessage | undefined>(notificationMessage);
  const navigate = useNavigate();

  useEffect(() => {
    if (notificationMessage?.errorCode === 401) {
      localStorage.removeItem('accessToken');
      navigate('/account/login');
    }
    const timeoutId = setTimeout(() => setMessage(undefined), 5000);
    return () => clearTimeout(timeoutId);
  });

  return (
    <NotificationGroup style={{ top: 48, right: 24 }}>
      <Fade>
        {message && (
          <Notification type={{ style: message.style }}>
            <span>{message.message}</span>
          </Notification>
        )}
      </Fade>
    </NotificationGroup>
  );
}

export interface FormNotificationProps {
  notificationMessage?: NotificationMessage;
  className?: string;
}

export function FormNotification({ notificationMessage, className }: FormNotificationProps) {
  const style = notificationMessage?.style;

  function getAlertClassName() {
    return style === "error" ? "alert-danger" :
      style === "success" ? "alert-success" :
        "";
  }

  function getDivClassName() {
    return ["d-flex", "alert", getAlertClassName(), className].join(" ");
  }

  return (
    <>
      {notificationMessage &&
        <div className={getDivClassName()}>
          <span>{notificationMessage.message}</span>
        </div>
      }
    </>
  );
}
