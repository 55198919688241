import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { SceneMode } from "cesium";
import { EntityBase, Project } from "../models/ProjectModels"
import viewApiService from "../api/viewApiService";
import { EntityView, EntityType, EntityViewStatus } from "../models/LegendModels";

const defaultSettings = {
  opacity: 100,
  show: true,
  showEntityLabels: true,
  showPointLabels: true,
  hasLabels: true
};

type ViewState = {
  project?: Project;
  views: EntityView[];
  mapMode: SceneMode;
  isLoading: boolean;
  error: any;
}

export const fetchProject = createAsyncThunk<Project, string, { rejectValue: any }>(
  'view/fetchProject',
  async function (id, { rejectWithValue }) {
    try {
      const { data } = await viewApiService.getProject(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchLatestProject = createAsyncThunk<Project | undefined, undefined, { rejectValue: any }>(
  'view/fetchLatestProject',
  async function (_, { rejectWithValue }) {
    try {
      const { data } = await viewApiService.getLatestProject();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSharedProject = createAsyncThunk<Project, string, { rejectValue: any }>(
  'view/fetchSharedProject',
  async function (code, { rejectWithValue }) {
    try {
      const { data } = await viewApiService.getSharedProject(code);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchEntitiesOfType = createAsyncThunk<EntityBase[], { id: string, type: EntityType }, { rejectValue: any }>(
  'view/fetchEntitiesOfType',
  async function({id, type}, { rejectWithValue }) {
    try {
      const { data } = await viewApiService.getProjectEntities(id, type);
      data.forEach(entity => {
        entity.type = type;
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function getInitialLegendItems() {
  const entityTypes = Object.values(EntityType).filter((v) => !isNaN(Number(v)));
  const views: EntityView[] = [];

  views.push({
    type: EntityType.baseMap,
    status: EntityViewStatus.loaded,
    entities: [],
    ...defaultSettings,
    disableSetting: false,
    hasLabels: false,
  });

  entityTypes.shift();
  entityTypes.forEach((type, index) => {
    views.push({
      type: type as EntityType,
      status: EntityViewStatus.initial,
      entities: [],
      ...defaultSettings,
      disableSetting: false
    });
  });

  return views;
}

const initialState: ViewState = {
  project: undefined,
  views: getInitialLegendItems(),
  mapMode: SceneMode.SCENE3D,
  isLoading: false,
  error: null
};

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    changeMapMode(state, action: PayloadAction<SceneMode>) {
      state.mapMode = action.payload;
    },
    changeViewEntitySetting(state, action: PayloadAction<EntityView>) {
      const settings = state.views.find(x => x.type === action.payload.type);
      if (settings) {
        settings.show = action.payload.show;
        settings.opacity = action.payload.opacity;
        settings.showEntityLabels = action.payload.showEntityLabels;
        settings.showPointLabels = action.payload.showPointLabels;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEntitiesOfType.pending, (state, action) => {
        const settings = state.views.find(x => x.type === action.meta.arg.type);
        if (settings) {
          settings.status = EntityViewStatus.loading;
        }
      })
      .addCase(fetchEntitiesOfType.fulfilled, (state, action) => {
        const settings = state.views.find(x => x.type === action.meta.arg.type);
        if (settings) {
          settings.entities = action.payload;
          settings.status = EntityViewStatus.loaded;
          settings.disableSetting = action.payload.length === 0
        }
      })
      .addMatcher(isFetchingProjectPending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isFetchingProjectFulfilled, (state, action: PayloadAction<Project | undefined>) => {
        state.project = action.payload;
        state.isLoading = false;
      })
      .addMatcher(isFetchingProjectRejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
      })
  },
});

const isFetchingProjectPending = isAnyOf(fetchProject.pending, fetchLatestProject.pending, fetchSharedProject.pending);
const isFetchingProjectFulfilled = isAnyOf(fetchProject.fulfilled, fetchLatestProject.fulfilled, fetchSharedProject.fulfilled);
const isFetchingProjectRejected = isAnyOf(fetchProject.rejected, fetchLatestProject.rejected, fetchSharedProject.rejected);

export const { 
  changeMapMode, 
  changeViewEntitySetting
} = viewSlice.actions;

export default viewSlice.reducer;
