import { useEffect } from "react";
import { Viewer } from "cesium";
import { useAppDispatch, useAppSelector } from "../../../../shared/hooks/commonHooks";
import { fetchEntitiesOfType } from "../../reducers/viewSlice";
import { EntityType } from "../../models/LegendModels";
import mapperFacade from "../../services/mapperFacade";

const EntityRenderer = (props: { type: EntityType, viewer: Viewer }) => {
  const { type, viewer } = props;

  const project = useAppSelector(state => state.view.project);
  const mapMode = useAppSelector(state => state.view.mapMode);
  const view = useAppSelector(state => state.view.views.find(x => x.type === type));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (project) {
      dispatch(fetchEntitiesOfType({ id: project.id, type }));
    }
  }, []);
  
  function render() {
    if (project && view?.entities && view.entities.length > 0 && view?.show === true) {
      setTimeout(() => {
        viewer.scene.requestRender();
      });
      return mapperFacade.mapEntity(project, view.entities, 
        { 
          mapMode, 
          globe: viewer.scene.globe, 
          opacity: view?.opacity as number, 
          showEntityLabels: view.showEntityLabels,
          showPointLabels: view.showPointLabels
        });
    }

    setTimeout(() => {
      viewer.scene.requestRender();
    });

    return null;
  }

  return render();
}

export default EntityRenderer;