import { useEffect, useRef, useState } from 'react';
import {
  Cesium3DTileset as TileSet,
  SceneMode,
  createWorldTerrainAsync,
  CesiumTerrainProvider,
  Viewer as CesiumViewer,
  IonImageryProvider,
  ImageryLayer as CesiumImageryLayer,
  Cartesian3,
  Entity as CesiumEntity,
  Rectangle,
  Color,
} from 'cesium';
import { Cesium3DTileset, CesiumComponentRef, Globe, ImageryLayer, Scene, Viewer as ResiumViewer, CameraFlyTo } from 'resium';
import { useAppSelector } from '../../../shared/hooks/commonHooks';
import { AppLoader } from '../../../shared/components/Indicators';
import Legend from './Legend';
import { EntityType } from '../models/LegendModels';
import EntityRenderer from './entities/EntityRenderer';
import viewApiService from '../api/viewApiService';
import { mapperBase } from '../services/mapperBase';
import mapperService from '../services/cameraPointService';

const showMap = { opacity: 100 };
const hideMap = { opacity: 0 };

interface ZoomOptions {
  entity?: CesiumEntity;
  rectangle?: Rectangle;
  rangeRatio?: number;
  singlePoint?: Cartesian3;
}

const Viewer = () => {
  const [zoomOptions, setZoomOptions] = useState<ZoomOptions | undefined>();
  const project = useAppSelector(state => state.view.project);
  const mapMode = useAppSelector(state => state.view.mapMode);
  const baseMapSettings = useAppSelector(state => state.view.views.find(x => x.type === EntityType.baseMap));
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [imageryProvider, setImageryProvider] = useState<IonImageryProvider>();
  const [terrainProvider, setTerrainProvider] = useState<CesiumTerrainProvider>();
  const [tileset, setTileset] = useState<TileSet>();
  const [cesiumViewer, setCesiumViewer] = useState<CesiumViewer>();

  const viewerRef = useRef<CesiumComponentRef<CesiumViewer>>(null);
  const layerRef = useRef<CesiumComponentRef<CesiumImageryLayer>>(null);

  useEffect(() => {
    async function loadImageryProvider() {
      try {
        const provider = await IonImageryProvider.fromAssetId(4, {});
        setImageryProvider(provider);
      } catch (error) {
        console.log(`Error loading imagery provider: ${error}`);
      }
    }

    async function loadTerrainProvider() {
      try {
        const provider = await createWorldTerrainAsync();
        setTerrainProvider(provider);
      } catch (error) {
        console.log(`Error creating world terrain: ${error}`);
      }
    }

    async function loadTileset() {
      try {
        const loadedTileset = await TileSet.fromIonAssetId(96188, {});
        setTileset(loadedTileset);
      } catch (error) {
        console.log(`Error loading tileset: ${error}`);
      }
    }

    Promise.all([
      loadImageryProvider(),
      loadTerrainProvider(),
      loadTileset()
    ]).then(() => {

      const interval = setInterval(() => {
        if (viewerRef.current?.cesiumElement?.scene.globe.tilesLoaded) {
          setCesiumViewer(viewerRef.current?.cesiumElement);

          clearInterval(interval);
        }
      }, 10);

    });
  }, []);

  useEffect(() => {
    if (project && cesiumViewer) {
      viewApiService.getCameraRectangle(project.id)
        .then(response => {
          const points = response.data;
          const projectedPoints = mapperBase.projectPoints(project, points);

          if (projectedPoints.length < 4) {
            setZoomOptions({singlePoint: mapperService.transformCameraPoint(project, points[0])});
          } else {
            const { zoomRectangle, zoomEntity, rangeRatio } = calculateZoomOptions(projectedPoints);
            cesiumViewer.entities.add(zoomEntity);

            setZoomOptions({
              entity: zoomEntity,
              rectangle: zoomRectangle,
              rangeRatio: rangeRatio
            });
          }
        });
    }
  }, [cesiumViewer]);

  useEffect(() => {
    if (zoomOptions) {
      flyTo(mapMode, zoomOptions, true);
    }
  }, [cesiumViewer, zoomOptions]);

  useEffect(() => {
    if (layerRef.current?.cesiumElement && baseMapSettings) {
      layerRef.current.cesiumElement.alpha = baseMapSettings.opacity / 100;
      layerRef.current.cesiumElement.show = baseMapSettings.show;
    }
  }, [baseMapSettings]);

  function handleMorphComplete() {
    setTimeout(() => {
      if (zoomOptions) {
        flyTo(mapMode, zoomOptions, false);
      }
    }, 0);
  }

  function flyTo(mapMode: SceneMode, zoomOptions: ZoomOptions, isInitialZoom: boolean) {
    if (cesiumViewer) {
      if (zoomOptions.singlePoint) {
        cesiumViewer.camera.flyTo({ 
          destination: zoomOptions.singlePoint,
          complete: () => {
            if (isInitialZoom) {
              const interval = setInterval(() => {
                if (cesiumViewer.scene.globe.tilesLoaded) {
                  setIsMapLoading(false);
                  cesiumViewer.scene.requestRender();

                  clearInterval(interval);
                }
              }, 10);
            }
          }
        });
        return;
      }

      if (mapMode === SceneMode.SCENE2D) {
        cesiumViewer.camera.flyTo({ destination: zoomOptions.rectangle! });
      }
      else if (mapMode === SceneMode.SCENE3D) {
        const options = { offset: { heading: 0.0, pitch: 100.0, range: zoomOptions.rangeRatio! * (-10) } };
        cesiumViewer.flyTo(zoomOptions.entity!, options)
          .then(_ => {
            if (isInitialZoom) {
              const interval = setInterval(() => {
                if (cesiumViewer.scene.globe.tilesLoaded) {
                  setIsMapLoading(false);
                  cesiumViewer.scene.requestRender();

                  clearInterval(interval);
                }
              }, 10);
            }
          });
      }
    }
  }

  function calculateZoomOptions(rectanglePoints: Cartesian3[]) {
    const topLeft = rectanglePoints[0];
    const bottomRight = rectanglePoints[2];
    const width = Math.abs(topLeft.x - bottomRight.x);
    const height = Math.abs(topLeft.y - bottomRight.y);
    const rangeRatio = Math.max(width, height);
    const zoomRectangle = Rectangle.fromCartesianArray(rectanglePoints);

    const zoomEntity = new CesiumEntity({
      rectangle: {
        coordinates: zoomRectangle,
        material: Color.fromAlpha(Color.WHITE, 0)
      }
    });

    return { zoomRectangle, zoomEntity, rangeRatio };
  }

  return (
    <>
      {isMapLoading && <AppLoader />}

      {(terrainProvider && imageryProvider && tileset) &&
        <>
          {!isMapLoading && <Legend />}

          <ResiumViewer
            className='map-viewer'
            ref={viewerRef}
            terrainProvider={terrainProvider}
            requestRenderMode={true}
            baseLayer={false}
            animation={false}
            timeline={false}
            vrButton={false}
            geocoder={false}
            homeButton={false}
            fullscreenButton={false}
            baseLayerPicker={false}
            infoBox={false}
            sceneModePicker={false}
            navigationHelpButton={false}
            style={isMapLoading ? hideMap : showMap}
          >
            <ImageryLayer ref={layerRef} imageryProvider={imageryProvider} />

            {mapMode == SceneMode.SCENE3D && <Cesium3DTileset url={tileset.resource} />}

            <Globe depthTestAgainstTerrain={true} />
            <Scene mode={mapMode} onMorphComplete={handleMorphComplete} />

            {cesiumViewer && <EntityRenderer type={EntityType.treeZones} viewer={cesiumViewer} />}
            {cesiumViewer && <EntityRenderer type={EntityType.buildingRows} viewer={cesiumViewer} />}
            {cesiumViewer && <EntityRenderer type={EntityType.receivers} viewer={cesiumViewer} />}
            {cesiumViewer && <EntityRenderer type={EntityType.groundZones} viewer={cesiumViewer} />}
            {cesiumViewer && <EntityRenderer type={EntityType.contourZones} viewer={cesiumViewer} />}
            {cesiumViewer && <EntityRenderer type={EntityType.terrainLines} viewer={cesiumViewer} />}
            {cesiumViewer && <EntityRenderer type={EntityType.roadways} viewer={cesiumViewer} />}
            {cesiumViewer && <EntityRenderer type={EntityType.barriers} viewer={cesiumViewer} />}

          </ResiumViewer>
        </>
      }
    </>
  );
}

export default Viewer;