import ReactDOM from 'react-dom/client';
import { Ion } from 'cesium';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.scss';
import cesiumService from './shared/services/cesiumService';
import App from './App';
import store from './store/store'

Ion.defaultAccessToken = cesiumService.accessToken;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
