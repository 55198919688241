import { Barrier, BuildingRow, ContourZone, EntityBase, GroundZone, Project, Receiver, Roadway, TerrainLine, TreeZone } from "../models/ProjectModels";
import { isBarrier, isBuildingRow, isContourZone, isGroundZone, isReceiver, isRoadway, isTerrainLine, isTreeZone } from "../models/TypesHelpers";
import barrierMapper from "./entitiesMappers/barrierMapper";
import buildingRowMapper from "./entitiesMappers/buildingRowMapper";
import contourZoneMapper from "./entitiesMappers/contourZoneMapper";
import groundZoneMapper from "./entitiesMappers/groundZoneMapper";
import receiverMapper from "./entitiesMappers/receiverMapper";
import roadwayMapper from "./entitiesMappers/roadwayMapper";
import terrainLineMapper from "./entitiesMappers/terrainLineMapper";
import treeZoneMapper from "./entitiesMappers/treeZoneMapper";
import { MappingConfig } from "./mapperBase";

class MapperFacade {
  mapEntity(project: Project, entities: EntityBase[], config: MappingConfig) {
    if (entities.length === 0) {
      return null;
    }

    if (isBarrier(entities[0])) {
      return barrierMapper.map(project, entities as Barrier[], config);
    }

    if (isBuildingRow(entities[0])) {
      return buildingRowMapper.map(project, entities as BuildingRow[], config);
    }

    if (isContourZone(entities[0])) {
      return contourZoneMapper.map(project, entities as ContourZone[], config);
    }

    if (isGroundZone(entities[0])) {
      return groundZoneMapper.map(project, entities as GroundZone[], config);
    }

    if (isTreeZone(entities[0])) {
      return treeZoneMapper.map(project, entities as TreeZone[], config);
    }

    if (isReceiver(entities[0])) {
      return receiverMapper.map(project, entities as Receiver[], config);
    }

    if (isRoadway(entities[0])) {
      return roadwayMapper.map(project, entities as Roadway[], config);
    }

    if (isTerrainLine(entities[0])) {
      return terrainLineMapper.map(project, entities as TerrainLine[], config);
    }

    return null;
  }
}

export default new MapperFacade();