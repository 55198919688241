import { Color } from "cesium";
import { GroundZone, Project } from "../../models/ProjectModels";
import { MapperBase, MappingConfig, ZoneProps } from "../mapperBase";

class GroundZoneMapper extends MapperBase {
  map(project: Project, groundZones: GroundZone[], config: MappingConfig): JSX.Element {
    const alpha = config.opacity / 100;
    const props: ZoneProps = {
      ...this.defaultZoneProps,
      ...this.getLabelAndPointMaterials(alpha),
      showEntityLabels: config.showEntityLabels,
      showPointLabels: config.showPointLabels,
      material: Color.fromAlpha(Color.GRAY, alpha)
    };

    const mapped = groundZones.map(groundZone =>
      this.mapPolygon(
        groundZone.id,
        groundZone.name,
        groundZone.points,
        props,
        project)
    );

    return (
      <>
        {mapped}
      </>
    );
  }
}

export default new GroundZoneMapper();
