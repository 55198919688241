import { Cartesian2, Cartesian3, Cartographic, Color, PolylineArrowMaterialProperty, createGuid } from "cesium";
import { Barrier, Project } from "../../models/ProjectModels";
import { MapperBase, MappingConfig } from "../mapperBase";
import { Entity, WallGraphics } from "resium";

class BarrierMapper extends MapperBase {
  map(project: Project, barriers: Barrier[], config: MappingConfig) {
    return (
      <>
        {this.map2D(project, barriers, config)}
        {this.map3D(project, barriers, config)}
      </>
    );
  }

  private map2D(project: Project, barriers: Barrier[], config: MappingConfig) {
    const alpha = config.opacity / 100;
    const mapped = barriers.map(x => this.mapPolyline(
      {
        ...x,
        ...this.getLabelAndPointMaterials(alpha),
        showEntityLabels: config.showEntityLabels,
        showPointLabels: config.showPointLabels,
        clampToGround: true,
        width: 10,
        labelPixelOffset: new Cartesian2(25, -30),
        polylineMaterial: new PolylineArrowMaterialProperty(Color.fromAlpha(Color.RED, alpha))
      },
      project)
    );

    return (
      <>
        {mapped}
      </>
    );
  }

  private map3D(project: Project, barriers: Barrier[], config: MappingConfig) {
    const mapped = barriers.map(x => this.mapBarrier3D(project, x, config));

    return (
      <>
        {mapped}
      </>
    )
  }

  private mapBarrier3D(project: Project, barrier: Barrier, config: MappingConfig) {
    const height = barrier.baseHeight;
    const points = this.projectPointsLatLong(project, barrier.points);
    const minHeights: number[] = [];
    const pointsFlatArray: number[] = [];
    points.forEach(x => {
      let minHeight = 0;
      if (config.globe) {
        const height = config.globe.getHeight(Cartographic.fromDegrees(x[0], x[1]));
        if (height && height > 0) {
          minHeight += height;
        }
      }

      pointsFlatArray.push(x[0], x[1], minHeight + height);
      minHeights.push(minHeight);
    });

    const positionsArray = Cartesian3.fromDegreesArrayHeights(pointsFlatArray);
    const material = Color.fromAlpha(Color.GREEN, config.opacity / 100);

    return (
      <>
        <Entity key={barrier.id}>
          <WallGraphics key={createGuid()}
            positions={positionsArray}
            material={material}
            minimumHeights={minHeights}
          />
        </Entity>
      </>
    )
  }
}

export default new BarrierMapper();
