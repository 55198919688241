import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { AppLoader } from "../../../shared/components/Indicators";
import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Field, FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { FormInput } from "../../../shared/components/Inputs";
import { copyIcon } from "@progress/kendo-svg-icons";
import { ShareProject } from "../api/shareProjectApi";
import { AppNotification, NotificationMessage } from "../../../shared/components/Notifications";

enum ShareState {
  inProgress = 1,
  success = 2,
  error = 3
}

export default function ShareDialog({ projectId, onClose }: any) {
  const [shareState, setShareState] = useState(ShareState.inProgress);
  const [link, setLink] = useState("");
  const [notificationMessage, setNotificationMessage] = useState<NotificationMessage>();

  function getTitle() {
    switch (shareState) {
      case ShareState.inProgress:
        return "Generating the link...";
      case ShareState.success:
        return "You can share the map via link";
      case ShareState.error:
        return "Failed to generate the link";
    }
  }

  async function shareProject() {
    try {
      const { url } = await ShareProject(projectId);
      setLink(url);
      setShareState(ShareState.success);
    } catch (error: any) {
      setShareState(ShareState.error);
    }
  }

  function handleCopyClick() {
    navigator.clipboard.writeText(link);
    setNotificationMessage(NotificationMessage.success("Link copied successfully"));
  }

  useEffect(() => {
    shareProject();
  });

  return (
    <>
      <Dialog height={270} width={700} title={getTitle()} onClose={onClose}>
        {shareState === ShareState.inProgress && <AppLoader />}

        {shareState === ShareState.error && <div className="d-flex justify-content-center">
          <p className="text-danger">
            Failed to generate a link to the the project. Please try again.
          </p>
        </div>}

        {shareState === ShareState.success && <Form initialValues={{link: link}}
          render={(formRenderProps) => (
            <FormElement id={"form"}>
              <FieldWrapper className="row d-flex">
                <div className="k-form-field-wrap col-md-9">
                  <Field name={"link"} type={"Text"} component={FormInput} disabled={true} />
                </div>
                <div className="col-md-3">
                  <Button svgIcon={copyIcon} fillMode={'solid'} themeColor={'primary'} className="w-100 mt-4"
                    onClick={handleCopyClick}>
                    Copy Link
                  </Button>
                </div>
              </FieldWrapper>

              <p className="mt-2 mb-2">
                You can stop sharing the project via project action menu at Projects page
              </p>
            </FormElement>
          )}
        />}

        <DialogActionsBar>
          <Button fillMode={'flat'} onClick={onClose}>Close</Button>
        </DialogActionsBar>

      </Dialog>
      <AppNotification notificationMessage={notificationMessage}></AppNotification>
    </>
  );
}
