import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "../components/Tabs/Tabs";
import { AppLoader } from "../../../shared/components/Indicators";
import { AppNotification, NotificationMessage } from "../../../shared/components/Notifications";
import { useAppDispatch, useAppSelector } from "../../../shared/hooks/commonHooks";
import { deleteProject, fetchProjectsList } from "../reducers/manageSlice";
import ProjectCard from "../components/ProjectCard/ProjectCard";
import GuestWarningCard from "../components/GuestWarningCard";
import accountContextService from "../../../account/services/accountContextService";
import { ProjectCollaborationType } from "../models/ProjectsModel";

const ManageProjects = () => {
  const { isLoading, error, projectsList} = useAppSelector(state => state.manage);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let isRegistered = getIsRegistered();

  if (accountContextService.isNew) {
    navigate(`/projects/view`);
  }

  useEffect(() => {
    isRegistered = getIsRegistered();
    dispatch(fetchProjectsList());
  }, []);

  if (isLoading) {
    return <AppLoader />;
  }

  function handleTabChange(collaborationType: ProjectCollaborationType) {
    dispatch(fetchProjectsList(collaborationType));
  }

  function handleView(id: string) {
    navigate(`/projects/view/${id}`);
  }

  function handleDelete(id: string) {
    dispatch(deleteProject(id))
      .then(() => dispatch(fetchProjectsList()));
  }

  function getIsRegistered(): boolean {
    return !!accountContextService.account;
  }

  const cards = projectsList.map(
    p =><ProjectCard key={p.id} project={p} onView={handleView} onDelete={handleDelete} />);

  return (
    <div className="my-3 mx-3 px-auto">
      {!isRegistered &&
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <GuestWarningCard />
          </div>
        </div>
      }

      <div className="mt-2">
        <Tabs onChange={handleTabChange} />
      </div>


      <div className="mt-3 d-flex flex-wrap" style={{ gap: 15 }}>
        {cards}
      </div>

      {error && <AppNotification notificationMessage={NotificationMessage.error(error)}></AppNotification>}
    </div>
  )
}

export default ManageProjects;