import { axios } from "../../../shared/api/axios";
import { EntityType } from "../models/LegendModels";
import { EntityBase, PointBase, Project } from "../models/ProjectModels";

interface GetProjectResponse {
  data: Project;
}

interface GetLatestProjectResponse {
  data: Project | undefined;
}

interface GetProjectEntitiesResponse {
  data: EntityBase[];
}

interface GetCameraRectangleResponse {
  data: PointBase[];
}

class ViewApiService {
  getProject(id: string): Promise<GetProjectResponse> {
    return axios.get(`/projects/${id}`);
  }

  getLatestProject(): Promise<GetLatestProjectResponse> {
    return axios.get('/projects/latest');
  }

  getSharedProject(code: string): Promise<GetProjectResponse> {
    return axios.get('/projects/shared', { params: { code } });
  }

  getProjectEntities(id: string, type: EntityType): Promise<GetProjectEntitiesResponse> {
    switch (type) {
      case EntityType.barriers:
        return this.getProjectBarriers(id);

      case EntityType.buildingRows:
        return this.getProjectBuildingRows(id);

      case EntityType.contourZones:
        return this.getProjectContourZones(id);

      case EntityType.groundZones:
        return this.getProjectGroundZones(id);

      case EntityType.treeZones:
        return this.getProjectTreeZones(id);

      case EntityType.receivers:
        return this.getProjectReceivers(id);

      case EntityType.roadways:
        return this.getProjectRoadways(id);

      case EntityType.terrainLines:
        return this.getProjectTerrainLines(id);
    
      default:
        throw new Error("Not supported entity type");
    }
  }

  getProjectTreeZones(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/tree-zones`);
  }

  getProjectBuildingRows(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/building-rows`);
  }

  getProjectReceivers(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/receivers`);
  }

  getProjectGroundZones(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/ground-zones`);
  }

  getProjectContourZones(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/contour-zones`);
  }

  getProjectTerrainLines(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/terrain-lines`);
  }

  getProjectRoadways(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/roadways`);
  }

  getProjectBarriers(id: string): Promise<GetProjectEntitiesResponse> {
    return axios.get(`/projects/${id}/barriers`);
  }

  getCameraRectangle(id: string): Promise<GetCameraRectangleResponse> {
    return axios.get(`/projects/${id}/zoom`);
  }
}

export default new ViewApiService();