import { Route } from "react-router";
import { UnauthorizedRoute } from "../../shared/components/Routes";
import Login from "../components/Login";
import Register from "../components/Register";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";

export default function useAccountRoutes() {
  return (
    <Route path="" element={<UnauthorizedRoute />}>
      <Route path="login" element={<Login />}></Route>
      <Route path="register" element={<Register />}></Route>
      <Route path="forgot-password" element={<ForgotPassword />}></Route>
      <Route path="reset-password" element={<ResetPassword />}></Route>
    </Route>
  )
}
