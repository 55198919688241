import { useState } from "react";
import { SvgIcon } from "@progress/kendo-react-common";
import { Field, FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Label, Error, Hint } from "@progress/kendo-react-labels";
import { eyeIcon, eyeSlashIcon } from "./Icons";

export function FormInput(fieldRenderProps: any) {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper>
      <Label
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
        className="k-form-label"
      >
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <Input
          valid={valid}
          type={type}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
}

export function PasswordField(fieldRenderProps: any) {
  const {
    onChange,
    validator,
    label,
    name,
    ...others
  } = fieldRenderProps;

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  function toggleIsPasswordShown() {
    setIsPasswordShown(!isPasswordShown);
  }

  function getTogglePasswordIcon() {
    return isPasswordShown ? eyeIcon : eyeSlashIcon;
  }

  return (
    <FieldWrapper>
      <div className="k-form-field-wrap" style={{ position: "relative" }}>
        <Field name={name} type={isPasswordShown ? "Text" : "Password"} component={FormInput} label={label}
          validator={validator} onChange={onChange} />

        <SvgIcon
          icon={getTogglePasswordIcon()}
          style={{ position: "absolute", top: "42px", right: "10px", cursor: "pointer" }}
          size={"large"}
          onClick={toggleIsPasswordShown} />
      </div>
    </FieldWrapper>
  );
}
